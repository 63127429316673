import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../../custom/inputfield/InputField";
import { Validators, spacialCharReplace } from "../../../utilities/Validator";
import Button from "../../custom/button/Button";
import useAxios from "../../../axiosinstance";
import Dropdown from "../../custom/dropdown/Dropdown";
import Message from "../../custom/toster/Message";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";
import { dateChangeUtc } from "../../../utilities/DateFormate";
import download_icon from "../../../images/download-icon.svg";
import doenload_csv from "../../../images/csv/users.csv";
// import { noteRefresh } from "../../../redux/actions";
import "./CreateUser.css";
import Select from "react-select";

function CreateUser({ toNextTab, toData }) {
  const [isLoading, setIsLoading] = useState(true);
  const axiosInstance = useAxios();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [sex, setSex] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [orgModel, setOrgModel] = useState("");
  const [formVaild, setFormVaild] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [SelectedCsv, setSelectedCsv] = useState();
  const [submitData, setSubmitData] = useState([]);
  const [notSubmitData, setNotSubmitData] = useState([]);
  const [orgapiData, setOrgapiData] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [Organization, setOrganization] = useState("");
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [platError, setPlatError] = useState(false);
  const [disble, setDisable] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [filteredPlatData, setFilterPlatData] = useState([]);
  const dispatch = useDispatch();
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const allPlatForms = useSelector(
    (state) => state.mainReducer.platFormData.platforms
  );

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const sexRef = useRef();
  const minDate = new Date(1900, 0, 1);
  const maxDate = new Date();
  const navigate = useNavigate();
  const orgModelRef = useRef();
  const roleIdRef = useRef();
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const language = useSelector((state) => state.mainReducer.languageData.arr);

  const handleLanchange = (e) => {
    setLan(e);
  };

  const [lan, setLan] = useState("");
  const handleFirstName = (e, err) => {
    setFirstNameErr(err);
    setFirstName(spacialCharReplace(e));
    setFormVaild(e.error ? e.error : false);
  };
  const handleLastName = (e, err) => {
    setLastNameErr(err);
    setLastName(spacialCharReplace(e));
    setFormVaild(e.error ? e.error : false);
  };
  const handleSex = (e) => {
    setSex(e);
  };

  const handleContinue = (e) => {
    setMessage(false);
    e.preventDefault();
    if (!firstName) {
      firstNameRef.current.errUpdate();
    }
    if (!lastName) {
      lastNameRef.current.errUpdate();
    }
    if (!sex) {
      sexRef.current.errUpdate();
    }
    if (!email) {
      emailRef.current.errUpdate();
    }
    if (!Organization) {
      orgModelRef.current.errUpdate();
    }
    if (!roleId) {
      roleIdRef.current.errUpdate();
    }

    if (selectedOption?.length === 0) {
      if (roleId === "3" || roleId === "1") {
        setPlatError(true);
        return;
      }
    }

    if (
      !firstName ||
      !lastName ||
      !sex ||
      !email ||
      !Organization ||
      !roleId ||
      firstNameErr ||
      lastNameErr ||
      emailErr ||
      platError
    ) {
      setFormVaild(true);
    } else if (formVaild) {
      // alert("form values not filled 2");
    } else {
      setDisable(true);
      let json;
      if (roleId === "3" || roleId === "1") {
        json = {
          email: email,
          firstname: firstName,
          lastname: lastName,
          dob: dateChangeUtc(startDate),
          gender: sex,
          akou_id: Organization,
          language:lan||'1',
          org_name:orgapiData.find((item)=>item.value===Organization)?.org_name,
          role_id: Number(roleId),
          platform: selectedOption.map((item) => {
            return {
              id: item.value,
              status: 1,
            };
          }),
        };
      } else {
        json = {
          email: email,
          firstname: firstName,
          lastname: lastName,
          dob: dateChangeUtc(startDate),
          gender: sex,
          language:lan ||'1',
          org_name:orgapiData.find((item)=>item.value===Organization)?.org_name||'',
          akou_id: Organization,
          role_id: Number(roleId),
          platform: [],
        };
      }
      setDisable(false);
      console.log("Jsonwaaa", json);
      // return;
      axiosInstance.current
        .post(`extorg/users/registerUser/${login.ak_id}`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          setMessage("User created successfully");
          setMessageType("success");
          setTimeout(() => {
            setMessage(false);
            navigate("/users-list");
          }, 2000);
        })
        .catch((err) => {
          setMessage(err.response.data.data);
          setMessageType("error");
          setDisable(false);
        });
    }
  };

  // console.log(orgModel , '++++++++++++++++++++')
  function onFileUpload(e) {
    const formData = new FormData();
    formData.append("file", SelectedCsv);
    axiosInstance.current
      .post(`extorg/users/registerMultipleUsers/${login.ak_id}`, formData)
      .then((response) => {
        if (response) {
          if (response.data.registered?.length > 0) {
            setMessage(" ");
            setMessageType("success");
            // setSubmitData(response.data.registered);
            setTimeout(() => {
              setMessage(false);
              navigate("/users-list");
            }, 2000);
          } else {
            setNotSubmitData(response.data.notRegistered);
            // setMessage(response.data.notRegistered);
            // setMessageType("error");
          }
          console.log("responce sucess--", response);
        }
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setMessageType("error");
        console.log("responce err--", err);
      });
  }
  useEffect(() => {
    if (typeof SelectedCsv === "object") {
      setMessage(false);
      onFileUpload();
    }
  }, [SelectedCsv]);


  const getAllOrgList = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extorg/admin/orgs?ak_id=${login.ak_id}`
      );

      const newData =Array.isArray( response.data.data) &&response.data.data.map((item) => ({
        value: item.id,
        label: item.o_nm,
        plan_id: item.plan_id,
        platform_id: item.platForm,
        org_name:item.org_name
      }));
      setOrgapiData(newData);
    } catch (err) {
      console.log("An error occurred while fetching data.");
      setOrgapiData([]);
    }
  };
  useEffect(() => {
    getAllOrgList();
  }, [login.ak_id]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleClose = () => {
    // setSubmitData([]);
    // setSelectedCsv({})
  };
  const handleClose1 = () => {
    setNotSubmitData([]);
  };
  const handleMail = (e) => {
    setEmail(e);
  };
  const handleOrg = (e) => {
    setOrganization(e);
    if (roleId === "3" || roleId === "1") {
      setSelectedOption([]);
    }
  };
  const handleType = (e) => {
    setRoleId(e);
    if (roleId === "3" || roleId === "1") {
      setSelectedOption([]);
    }
  };

  useEffect(() => {
    if (Number(login.type) === 6) {
      setAllRoles([
        { value: 2, label: "Org Admin" },
        { value: 3, label: "HCP" },
        { value: 1, label: "Patient" },
        { value: 4, label: "Secretary" },
      ]);
    }
    if (Number(login.type) === 2) {
      setAllRoles([
        // { value: 2, label: "Org Admin" },
        { value: 3, label: "HCP" },
        { value: 1, label: "Patient" },
        { value: 4, label: "Secretary" },
      ]);
    }
  }, [login.type]);

  const handleselection = (selectedValues) => {
    setSelectedOption(selectedValues);
    setPlatError(false);
  };



  // Find the entry with the matching org_ids
  let filteredOrg = orgapiData.find((entry) => entry.value === Organization);


  // Extract the platForm array if the entry is found
  let result = filteredOrg ? filteredOrg.platform_id : [];



  useEffect(() => {
    setFilterPlatData(
      allPlatForms.filter((option) => result.includes(option.value))
    );
  }, [Organization]);



  return (
    <>
      <div className="create_hcp_screen mb_200">
        <div className="row page_header">
          <div className="col-sm-6 col-md-6">
            <h4 className="ph_title">Add User</h4>
          </div>
          <div className="col-sm-6 col-md-6 text-end"></div>
        </div>

        <div className="panel">
          {message && <Message message={message} type={messageType} />}
          {/* <form> */}
          <div className="panel-body p_25 pt_50 pb_50">
            <div className="dc_reg_inner">
              <div className="">
                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <Dropdown
                      data={orgapiData}
                      label="Organization*"
                      styleClass="mxwp_80_select br_10"
                      value={Organization}
                      placeholder="Select"
                      ref={orgModelRef}
                      onChange={handleOrg}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <Dropdown
                      data={allRoles}
                      label={"Type *"}
                      styleClass="br_10 mxwp_80_select"
                      value={roleId}
                      ref={roleIdRef}
                      placeholder="Select"
                      onChange={handleType}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label="First Name*"
                      value={firstName}
                      name="firstName"
                      type="text"
                      placeholder="First Name*"
                      onChange={handleFirstName}
                      ref={firstNameRef}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                        {
                          check: Validators.spacialName,
                          message: "No special characters are allowed",
                        },
                        {
                          check: Validators.textNum,
                          message: "Numbers are not allowed",
                        },
                        {
                          check: Validators.maxfifty,
                          message: "Maximum 50 character are allowed",
                        },
                      ]}
                      styleClass="br_10 mxwp_80"
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label="Last Name*"
                      value={lastName}
                      name="lastName"
                      type="text"
                      placeholder="Last Name*"
                      onChange={handleLastName}
                      ref={lastNameRef}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                        {
                          check: Validators.spacialName,
                          message: "No special characters are allowed",
                        },
                        {
                          check: Validators.textNum,
                          message: "Numbers are not allowed",
                        },
                        {
                          check: Validators.maxfifty,
                          message: "Maximum 50 character are allowed",
                        },
                      ]}
                      styleClass="br_10 mxwp_80"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label={"Email*"}
                      value={email}
                      ref={emailRef}
                      type="text"
                      placeholder="Email"
                      onChange={handleMail}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                        {
                          check: Validators.email,
                          message: "Only contain Email",
                        },
                      ]}
                      styleClass="br_10 mxwp_80"
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <Form.Group className="mb-3" controlId="fromDate">
                      <Form.Label>
                        {"DOB*"}
                        {/* <i style={{ "font-style": "italic" }}>(DD/MM/YYYY)*</i> */}
                      </Form.Label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        minDate={minDate}
                        maxDate={maxDate}
                        className="form-control mxwp_80 mb_0 br_10"
                        selected={new Date(startDate)}
                        onChange={(date) => setStartDate(date)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        validators={[
                          {
                            check: Validators.required,
                            message: "This field is required",
                          },

                          {
                            check: Validators.number,
                            message: "No special characters are allowed",
                          },
                        ]}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <Dropdown
                      data={[
                        { value: 1, label: "Male" },
                        { value: 2, label: "Female" },
                        { value: 3, label: "Do not want to disclose" },
                      ]}
                      label={"Sex *"}
                      styleClass="br_10 mxwp_80_select"
                      value={sex}
                      ref={sexRef}
                      placeholder="Select"
                      onChange={handleSex}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <Dropdown
                      data={Array.isArray(language)? language.map((item) => {
                        return {
                          ...item,
                          value: item.id,
                        };
                      }):[]}
                      label="Language"
                      value={lan}
                      onChange={handleLanchange}
                      placeholder="Select"
                      styleClass="br_10 mxwp_80_select"
                    />
                  </div>
                </div>
                <div className="row">
                  {roleId === "3" || roleId === "1" ? (
                    <div className="col-sm-5 col-md-5 mb_20">
                      <label>Platform *</label>
                      <Select
                        value={selectedOption}
                        isMulti
                        onChange={handleselection}
                        options={filteredPlatData}
                        className="multi_select_drp_select_plat_users"
                        placeholder="Select Platforms"
                      />
                      {console.log(
                        "selecteddddoptions",
                        selectedOption,
                        platError
                      )}
                      {selectedOption?.length === 0 && (
                        <>
                          {platError && (
                            <span className="text-danger">
                              This field is required
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  <div className="col-md-10">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                      className="mxwp_90"
                    >
                      <hr style={{ flex: 1, borderColor: "#3f2783" }} />
                      <span
                        style={{
                          padding: "0 10px",
                          fontWeight: "bold",
                          color: "#3f2783",
                          marginTop: "-20px",
                        }}
                      >
                        OR
                      </span>
                      <hr style={{ flex: 1, borderColor: "#3f2783" }} />
                    </div>
                    <div className="error mxwp_90">
                      {submitData?.length !== 0 ? (
                        <>
                          <label> Patient Added</label>
                          <div className="alert alert-success alert-dismissible">
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="alert"
                              onClick={() => handleClose()}
                            />
                            <div className="patient_box scrollbar_vert">
                              {submitData.map((ele) => (
                                <p>
                                  <strong>{ele.data.email}</strong>{" "}
                                  {ele.message}
                                </p>
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {notSubmitData?.length !== 0 ? (
                        <>
                          <label> Patient Not Added</label>
                          <div className="alert alert-danger alert-dismissible ">
                            <button
                              type="button"
                              className="btn-close"
                              onClick={handleClose1}
                            />
                            <div className="patient_box scrollbar_vert">
                              {notSubmitData.map((ele) => (
                                <p>
                                  <strong>{ele.email}</strong> {ele.data}
                                </p>
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <span
                      class="ms-auto btn btn_org mxwp_90"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    >
                      <p>Import .csv file</p>
                      <label className="browser_file" for="myfileCsv">
                        {" "}
                        Browser File
                      </label>
                      <input
                        type="file"
                        id="myfileCsv"
                        name="myfileCsv"
                        hidden
                        accept=".csv"
                        style={{ display: "none" }}
                        onChange={(event) => {
                          setSelectedCsv(event.target.files[0]);
                        }}
                        // style={{ width: "100%" }}
                      />
                    </span>
                  </div>
                  <div className="col-md-10">
                    <div className="mxwp_90">
                      <a
                        href={doenload_csv}
                        download="myDemoFile"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        title="Download a demo CSV file"
                      >
                        <div className="mt-2 float-end">
                          <label className="c_pointer">Example</label>
                          <img className="ms-2" src={download_icon} alt="" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <Button
                      onClick={handleContinue}
                      enable={disble}
                      value="Continue"
                      buttonStyle="next_btn btn_fill clk_btn_disbled"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
}

export default CreateUser;
