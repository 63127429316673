import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
import DrodownChannel from "../custom/dropdown/DrodownChannel";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { useSearchParams } from "react-router-dom";
import { Validators } from "../../utilities/Validator";
import VideosListTable from "./VideosListTable";
import Message from "../custom/toster/Message";
import { useSelector, useDispatch } from "react-redux";
import { noteRefs } from "../../redux/actions";
import "./Videos.css";
import DropdownTags from "../custom/dropdown/DropdownTags";
import DropdownOrg from "../custom/dropdown/DropdownOrg";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

function Videos() {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const dispatch = useDispatch();

  const videoTypeRef = useRef();
  const videoOrgRef = useRef();
  const postedByRef = useRef();
  const tagsRef = useRef();
  const channelsRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const akTokenOrg = localStorage.getItem("AKTOKENORG")
  let searchData = searchParams.get("q");
  const [qData, setqData] = useState("");
  // console.log('search data ', qData)
  let searchType = searchParams.get("search");
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const channels = useSelector((state) => state.mainReducer.channels.channelsData)
  const orgchannels = useSelector((state) => state.mainReducer.channels.org_channel)

  const profile = useSelector((state) => state.mainReducer.loginData.arr);

  // console.log("profile",profile[0].type)

  const [isLoading, setIsLoading] = useState(true);
  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  const login = "";
  // const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const [videosData, setData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  // const [status, setStatus] = useState("");
  const [searchEnable, setsearchEnable] = useState("N");
  const [startDate, setStartDate] = useState("");
  // const [startDate, setStartDate] = useState(new Date());

  const [offset, setOffset] = useState(25);
  const [limit, setLimit] = useState(25);
  const [sortType, setSortType] = useState("last_created");
  const [totaldata, setTotaldata] = useState("");
  const [chanel, setChanel] = useState("")
  const [videoType, setVideoType] = useState("");
  const [loader1, setloader1] = useState(false);
  const [lastTypingTime, setLastTypingTime] = useState(null);
  const [totalData, setTotalData] = useState("")
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);


  const [videoTypeData, setVideoTypeData] = useState([
    {
      label: "Video",
      value: 1
    },
    {
      label: "Live Event",
      value: 2
    },
  ]);




  const [videoOrgList, setVideoOrgList] = useState([]);
  const [videoOrg, setVideoOrg] = useState("");

  const [postedBy, setPostedBy] = useState([]);
  const [postedByData, setPostedByData] = useState([
    {
      label: "Akunah",
      value: 1
    },
    {
      label: "Organization",
      value: 2
    }
  ]);

  const [tags, setTags] = useState("");
  const [tagsData, setTagsData] = useState([]);

  const [channelsData, setChannelsData] = useState([]);

  const handleCreateEvent = () => {
    navigate(`/create-event/1`)
  }


  useEffect(() => {
    setqData(searchData);
    setsearchEnable(searchType);
  }, [searchData, searchType]);

  const removeQueryParams = () => {
    const param = searchParams.get("q");

    if (param) {
      // 👇️ delete each query param
      searchParams.delete("q");
      searchParams.delete("search");

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  };

  const handleReset = (e) => {
    setFirstName("");
    setLastName("");
    setStartDate("");
    setVideoType("")
    setTags("")
    setChanel("")
    setPostedBy([])
    setEmail("");
    if (searchType === "Y") {
      setsearchEnable("Y");
    }
  };
  const advsearchToFirstName = (event) => {
    setLastTypingTime(new Date().getTime())
    setFirstName(event);
    removeQueryParams();
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(25);
  };
  const inviteEmailChange = (e) => {
    console.log("test", e);
  };
  const advsearchToMail = (event) => {
    setEmail(event);
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(25);
  };
  const handleDob = (event) => {
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(25);
    setStartDate(event);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const getData = async () => {
      //   const response = await axiosInstance.current.get(
      //     `extapp/doctors/getAllPatient/${login.ing_ak_id}?offset=0&firstname=${firstName}&lastname=${lastName}&dob=${startDate}&email=${email}&sortType=${sortType}&limit=${limit}`
      //   );
      //   setData(response.data.data);
      //   setTotaldata(response.data.total);
      // };
      // if (searchEnable !== "Y") {
      //   getData();
    }
  }, []);



  const fetchMoreData = () => {
    addMoreData()
  };
  const handleSort = (e) => {
    setSortType(e);
  };
  const handleVideotype = (e) => {
    setVideoType(e);
    setOffset(25)

  };
  const handleVideoOrg = (e) => {
    setVideoOrg(e);
    setOffset(25)
  };
  const handlePostedBy = (e) => {
    setPostedBy(e);
    setOffset(25)
  };
  // const handleTags = (e) => {
  //   setTags(e);
  //   setOffset(25)
  // };
  const handleTags = (event, value) => {
    if (value) {
      setSelectedTags(value);
      setTags(value.id)
      console.log(value, "valueeeeeeeeeee");
    }else{
      setTags("")
      setSelectedTags(null)
    }
    setOffset(25)
  };
  // const handleChannels = (e) => {
  //   setChanel(e);
  //   setOffset(25)
  // };
  const handleChannels = (event, value) => {
    if (value) {
      setSelectedChannel(value);
      setChanel(value.id)
      console.log(value, "valueeeeeeeeeee");
    } else {
      setChanel("")
      setSelectedChannel(null)
    }
    setOffset(25)
  };


  const videoList = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extorg/admin/videoList?page=${0}&limit=${limit}&ak_id=${akTokenOrg}&typeId=${videoType}&akou_id=${videoOrg}&posted_by=${postedBy}&tag=${tags}&channel=${chanel}&title=${firstName}`
      );
      if (response.status === 200) {
        setloader1(true)
        setData(response.data.data)
        setTotalData(response.data.total)
      }

    } catch (error) {
      console.log("error", error)
    }
  }

  console.log("response-video", totalData)

  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const videoList = async () => {
          try {
            const response = await axiosInstance.current.get(
              `extorg/admin/videoList?page=${0}&limit=${limit}&ak_id=${akTokenOrg}&typeId=${videoType}&akou_id=${videoOrg}&posted_by=${postedBy}&tag=${tags}&channel=${chanel}&title=${firstName}`
            );
            if (response.status === 200) {
              setloader1(true)
              setData(response.data.data)
              setTotalData(response.data.total)
            }
          } catch (error) {
            console.log("An error occurred while fetching data.");
          }
        };

        videoList();



      }, 1000);
      return () => clearTimeout(timer)
    }
  }, [firstName])



  const getTagsList = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extorg/admin/getAllTags`
      );
      setTagsData(response.data.data)
    } catch (error) {
      console.log("error", error)
    }
  }


  useEffect(() => {
    getTagsList()
  }, [])


  const addMoreData = async () => {
    setOffset(offset + 25);
    const response = await axiosInstance.current.get(
      `extorg/admin/videoList?page=${offset}&limit=${limit}&ak_id=${akTokenOrg}&typeId=${videoType}&akou_id=${videoOrg}&posted_by=${postedBy}&tag=${tags}&channel=${chanel}&title=${firstName}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setData(videosData.concat(resdata));
    }, 500);
  };




  useEffect(() => {
    videoList()

  }, [videoType, postedBy, tags, chanel, videoOrg, dataRefe])



  // useEffect(() => {
  //   if (firstName.length > 2 || firstName.length === 0) {
  //     setTimeout(() => {
  //       videoList()
  //     }, 2000);

  //   }
  // }, [firstName]);


  useEffect(() => {
    if (orgchannels) {
      setVideoOrgList(orgchannels)
    }
  }, [orgchannels])






  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="post__list mb_100">
            <div className="panel mb_30">
              <div className="panel-heading  pannel_pd">
                <div className="row">
                  <div className="col-sm-6 col-md-6 pt_30">
                    <h4>Akunah Med Videos/Events</h4>
                  </div>
                  <div className="col-sm-6 col-md-6 pt_30 text-right">
                    <Button
                      onClick={handleCreateEvent}
                      value="+ Create an event"
                      buttonStyle="btn_fill w_180 mr_20"
                    />
                    <Button
                      onClick={() => navigate("/create-post")}
                      value="+ Upload Videos"
                      buttonStyle="btn_fill w_180"
                    />
                  </div>
                </div>
              </div>
              <div className="panel-body p_25">
                <>
                  <div className="adv_search_sec">
                    <h4 className="mb_30">{"Advanced Search"}</h4>
                    {/* <form onSubmit={handleSubmit}> */}

                    <div className="row">
                      {
                        profile[0].type === 6 ? ("") : (
                          <div className="col-sm-6 col-md-3">
                            <DropdownOrg
                              data={videoOrgList}
                              label="Organization"
                              value={videoOrg}
                              onChange={handleVideoOrg}
                              placeholder="Select"
                              styleClass="mxw_100 mb_20"
                              ref={videoOrgRef}
                            // validators={[
                            //   {
                            //     check: Validators.required,
                            //     message: "This field is required",
                            //   },
                            // ]}
                            />
                          </div>
                        )
                      }

                      <div className="col-sm-6 col-md-3">
                        <InputField
                          label="Title"
                          value={firstName}
                          type="text"
                          name="firstName"
                          placeholder="Enter title"
                          styleClass="mxw_100 mb_20"
                          onChange={advsearchToFirstName}
                        />
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <Dropdown
                          data={videoTypeData}
                          label="Type"
                          value={videoType}
                          onChange={handleVideotype}
                          placeholder="Select"
                          styleClass="mxw_100 mb_20"
                          ref={videoTypeRef}
                        // validators={[
                        //   {
                        //     check: Validators.required,
                        //     message: "This field is required",
                        //   },
                        // ]}
                        />
                      </div>
                      <div className="col-sm-6 col-md-3">

                        {
                          profile[0].type === 6 ? (
                            <Dropdown
                              data={postedByData}
                              label="Posted By"
                              value={postedBy}
                              onChange={handlePostedBy}
                              placeholder="Select"
                              styleClass="mxw_100 mb_20"
                              ref={postedByRef}
                            // validators={[
                            //   {
                            //     check: Validators.required,
                            //     message: "This field is required",
                            //   },
                            // ]}
                            />
                          ) : ("")
                        }

                      </div>
                      <div className="col-sm-6 col-md-3">
                      <div className="dropdown_main_1 dropdown_main_12 d-block">
                        {/* <DropdownTags
                          data={tagsData}
                          label="Tags"
                          value={tags}
                          onChange={handleTags}
                          placeholder="Select"
                          styleClass="mxw_100 mb_20"
                          ref={tagsRef}
                        // validators={[
                        //   {
                        //     check: Validators.required,
                        //     message: "This field is required",
                        //   },
                        // ]}
                        /> */}
                        <div className="">
                          <label>Tags</label>
                        </div>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          className="tags-channels-autocomeple w-100"
                          style={{ borderRadius: "20px" }}
                          options={tagsData}
                          getOptionLabel={(option) => `${option.name}`}
                          onChange={handleTags}
                          renderInput={(params) => <TextField placeholder="Select tags" 
                            InputLabelProps={{
                              shrink: false,
                            }}
                            sx={{
                              "& ::placeholder": {
                                color: "#3f2783", // Change this to the desired color
                              },
                            }}
                            {...params} label="" />}
                        />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                      <div className="dropdown_main_1 dropdown_main_12 d-block">
{/* <DrodownChannel
                          data={channels}
                          label="Channels"
                          value={chanel}
                          onChange={handleChannels}
                          placeholder="Select"
                          styleClass="mxw_100 mb_20"
                          ref={channelsRef}
                        // validators={[
                        //   {
                        //     check: Validators.required,
                        //     message: "This field is required",
                        //   },
                        // ]}
                        /> */}
                        <div className="">
                          <label>Channels</label>
                        </div>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          className="tags-channels-autocomeple w-100"
                          style={{ borderRadius: "20px" }}
                          options={channels}
                          getOptionLabel={(option) => `${option.name}`}
                          onChange={handleChannels}
                          renderInput={(params) => <TextField placeholder="Select channel"
                            InputLabelProps={{
                              shrink: false,
                            }}
                            sx={{
                              "& ::placeholder": {
                                color: "#3f2783", // Change this to the desired color
                              },
                            }}
                            {...params} label="" />}
                        />
                      </div>
                        
                      </div>
                      <div className="col-sm-6 col-md-3 pt_30">
                        <Button
                          onClick={handleReset}
                          value="Reset"
                          buttonStyle="btn_fill mr_20"
                        />
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                  <hr className="mt-5" />
                  <div className="row">
                    <div className="col-sm-6 col-md-6">
                      <h4 className="mb-3 ml_25">{"List of Videos"}</h4>

                      <p className="mb_30 ml_25">
                        {totalData} {totalData > 1 ? "Results" : "Result"}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      {/* <SortDropdown onChange={handleSort} alp={true} /> */}
                    </div>
                  </div>
                  {!loader1 ? <div className="no_data">
                    loading...
                  </div> : (
                    <>
                      {
                        videosData && videosData.length > 0 ? (
                          <VideosListTable
                            videosData={videosData}
                            updatePagination={fetchMoreData}
                            totalData={totalData}

                          />
                        ) : (
                          <div className="no_data">No data found</div>
                        )}

                    </>
                  )
                  }

                  {/* {videosData && videosData.length > 0 ? (
                    <VideosListTable
                      videosData={videosData}
                      updatePagination={fetchMoreData}

                    />
                  ) : (
                    <div className="no_data">No Data Found</div>
                  )} */}
                </>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Videos;
