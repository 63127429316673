import React, { useState, useEffect, useRef } from "react";
// import {  useNavigate } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
import { Validators, spacialCharReplace } from "../../utilities/Validator";
import Button from "../custom/button/Button";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import ChunkUpload from "../custom/chunk-upload/ChunkUpload";
import Switch from "../custom/switch/Switch";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux'
import { noteRefs } from "../../redux/actions";
import useAxiosUpload from "../../axiosinstanceUpload";
import DropdownOrg from "../custom/dropdown/DropdownOrg";



function EditVideoDetails({ toNextTab, toData }) {
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const receivedData = location.state;
  const videoData = receivedData ? receivedData.data : []
  console.log("videoData",receivedData)
  const [arr, setArr] = useState([])

  const axiosInstanceUpload = useAxiosUpload();

  let catids = []
  let channelIds = []
  const akTokenOrg = localStorage.getItem("AKTOKENORG")
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const profile = useSelector((state) => state.mainReducer.loginData.arr);
  const orgchannels = useSelector((state) => state.mainReducer.channels.org_channel)
  
  const [isLoading, setIsLoading] = useState(true);
  const axiosInstance = useAxios();
  const [videoTitle, setVideoTitle] = useState(videoData.title || "");
  const [vidId, setVidId] = useState(videoData.videoId)
  
  const [video_key, setVideoKey] = useState(videoData.video_key || "");
  const [type_id, setTypeId] = useState("1");
  const[duration,setDuration]=useState(videoData.duration || "")
  const[link,setLink]=useState(videoData.link || "")
const[video_size,setVideoSize]=useState(videoData.video_size || "")


const [thumbnail1, setThumbnail1] = useState(null);
const [thumbnail2, setThumbnail2] = useState(null);
const [progress, setProgress] = useState();
const [large, setLarge] = useState(videoData.large_thumb || "");
const [medium, setMedium] = useState(videoData.medium_thumb || "");
const [largeloader, setLargeloader] = useState(false);
const [small, setSmall] = useState(false);

  const [lastName, setLastName] = useState("");
  const [formVaild, setFormVaild] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [load, setVideoload] = useState(false);
  const [categortyData, setCategoryData] = useState([]);
  const [channelsData, setChannelsData] = useState([]);
  const [enabled, setEnabled] = useState(videoData.enabled)
  const [disabled, setDisabled] = useState(true)
  const[disble,setDisable]=useState(false)


  const [categoryId, setCategory] = useState(videoData.tags || []);
  const [channelsId, setChannelsId] = useState(videoData.channels || []);



  console.log("large",large,medium)
  categoryId.map((ele) => {
    catids.push(ele.id)
  })

  channelsId.map((ele) => {
    channelIds.push(ele.id)
  })


  const [isChecked, setIsChecked] = useState(false);
  const [description, setDescription] = useState(videoData.description);
  const [allOrgList, setAllOrgList] = useState([]);
  const [allSelectOrg, setAllSelectOrg] = useState(videoData.org_id || "");
  const allOrgRef = useRef();
  const lastNameRef = useRef();
  const descriptionRef = useRef();
  const videoTitleRef = useRef()
  const multiselectRef = useRef()
  // const navigate = useNavigate();-

  const getTagsList = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extorg/admin/getAllTags`
      );
      setArr(response.data.data)
    } catch (error) {
      console.log("error", error)
    }
  }


  const channelList = async () => {
    const response = await axiosInstance.current.get(
      `extorg/admin/getChannelList?ak_id=${akTokenOrg}&keyword=${""}&orgKey=${""}&meta=${2}` //OrgAdd
    );
    // dispatch(channeldata(response.data.data))
    setChannelsData(response.data.data)
  }

  const channelListBySpecificOrg = async () => {
    const response = await axiosInstance.current.get(
      `extorg/admin/getChannelList?ak_id=${akTokenOrg}&keyword=${""}&orgKey=${allSelectOrg}&meta=${2}` //OrgAdd
    );
    // dispatch(channeldata(response.data.data))
    setChannelsData(response.data.data)
  }


  useEffect(() => {
    if (videoData.length===0) {
      navigate("/post-list")
    }
  }, [videoData])




  useEffect(() => {
    getTagsList()
    if(profile[0].type===6){
      channelList()
    }else{
      channelListBySpecificOrg()
    }
  }, [])




  const handleVideoTitle = (e, err) => {
    // setFirstNameErr(err);
    setVideoTitle(spacialCharReplace(e));
    // setFormVaild(e.error ? e.error : false);
  };

  const handleDescription = (e) => {
    setDescription(e);
  };

  // const handleContinue = (e) => {
  //   setMessage(false);
  //   e.preventDefault();
  //   if (!lastName) {
  //     lastNameRef.current.errUpdate();
  //   }

  //   if (!videoTitle || !description) {
  //     // alert("form values not filled 1");
  //     setFormVaild(true);
  //   } else if (formVaild) {
  //     // alert("form values not filled 2");
  //   } else {
  //     // alert("form values not filled 3");
  //     const json = JSON.stringify({
  //       videoTitle: videoTitle,
  //       lastname: lastName,
  //     });
  //     axiosInstance.current
  //       .post(`extapp/doctors/new_registration`, json, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       })
  //       .then((res) => {
  //         console.log(res.data.ak_id);
  //         toData(res.data.ak_id);
  //       })
  //       .catch((err) => {
  //         setMessage(err.response.data.message);
  //         setMessageType("error");
  //       });
  //   }
  // };



  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const [data, setData] = useState({
    filelength: "",
  });
  const sendData = (data) => {
    setData(data);
  };



  const selectOption = (event) => {
    const getID = [];
    for (let index = 0; index < event.length; index++) {
      getID.push(event[index]);
    }
    setCategory(getID);
    console.log("getID", getID)
  };

  const selectOption1 = (event) => {
    const getChannelId = [];
    for (let index = 0; index < event.length; index++) {
      getChannelId.push(event[index]);
    }
    setChannelsId(getChannelId);
    console.log("getChannelId", getChannelId)
  };



  const handleSwitchChange = (status) => {

    if (status === 1) {
      setEnabled(0)
    } else {
      setEnabled(1)
    }
  };


  const handleAllOrg = (e) => {
    setAllSelectOrg(e);
    setChannelsId([])
    multiselectRef.current.resetSelectedValues();
  };




  useEffect(() => {
    console.log("comming here1")
    const handleBeforeUnload = (e) => {
      // Check if the user is refreshing the page
      if (e.clientY < 0) {
        // User is refreshing, redirect to the desired URL
        console.log("comming here2")

        navigate('/post-list');
      }
    };

    // Attach the event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate]);









  const handleContinue = async () => {

    if (!videoTitle) {
      videoTitleRef.current.errUpdate()
    }
    if (!description) {
      descriptionRef.current.errUpdate()
    }


    if (
      !videoTitle ||
      !description
    ) {
    } else {
      setDisable(true)
      let json = {
        typeId:type_id,
        title:videoTitle,
        tags: catids,
        enabled:enabled,
        link:link,
        large,
        medium,
        // publish_date,
        created_on: new Date().toISOString(),
        description:description,
        file_size: data.filelength,
        // org_id: hcpDrop,
        channels: channelIds,
        video_key:video_key,
        created_at : new Date(),
        updated_at : new Date(),
      }

      const response = await axiosInstance.current.put(
        `extorg/admin/updateContent?id=${vidId}&video_key=${video_key}&large=${large}&medium=${medium}`, json, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      ).then((res) => {
        setMessage("Video Update Successfully")
        setMessageType("success");
        dispatch(noteRefs(!dataRefe))
        navigate("/post-list")
        setTimeout(() => {
          setMessage(false)
        }, 3000);
      }).catch((err) => {
        setMessage("Video Not Update")
        setMessageType("error");

      })


    }

  }






  useEffect(() => {
    if (thumbnail1) {
      const ext = thumbnail1.name.split(".").pop();
      let fileName = `720x405.${ext}`;
      setLargeloader(true);
      // console.log(fileName);
      setLarge(fileName);

      const formData = new FormData();
      formData.append("file", thumbnail1);
      axiosInstanceUpload.current
        .post(
          `uploadthumbnail?video_key=${video_key}&field_name=${fileName}`,
          formData
        )
        .then((response) => {
          setLargeloader(false);
          // console.log(response);
        });
    }
  }, [thumbnail1]);




  useEffect(() => {
    if (thumbnail2) {
      const ext = thumbnail2.name.split(".").pop();
      let fileName = `480x270.${ext}`;
      setSmall(true);
      setMedium(fileName);
    
      const formData = new FormData();
      formData.append("file", thumbnail1);
      axiosInstanceUpload.current
        .post(
          `uploadthumbnail?video_key=${video_key}&field_name=${fileName}`,
          formData
        )
        .then((response) => {
          setSmall(false);

          // console.log(response);
        });
    }
  }, [thumbnail2]);

  const config = {
    onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
  };




  useEffect(() => {
    if (orgchannels) {
      setAllOrgList(orgchannels)
    }
  }, [orgchannels])





  return (
    <>
      <div className="create_video_screen mb_200">
      {message && <Message message={message} type={messageType} />}

        <div className="row page_header">
          <div className="col-sm-6 col-md-6">
            <h4 className="ph_title">Edit Video</h4>
          </div>
          <div className="col-sm-6 col-md-6 text-end"></div>
        </div>

        <div className="panel">
          {message && <Message message={message} type={messageType} />}
          {/* <form> */}
          <div className="panel-body p_25 pt_50 pb_50">
            <div className="dc_reg_inner">
              <div className="">
              {
                  profile[0].type === 6 ? ("") : (
                    <div className="row">
                      <div className="col-sm-10 col-md-10">
                        <DropdownOrg
                          data={allOrgList}
                          label="Organization"
                          value={allSelectOrg}
                          onChange={handleAllOrg}
                          placeholder="Select"
                          styleClass="mxwp_90_select mb_20 br_10"
                          ref={allOrgRef}
                          disabled={disabled}
                          validators={[
                            {
                              check: Validators.required,
                              message: "This field is required",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  )
                }
                <div className="row">
                  <div className="col-sm-10 col-md-10 mb_20">
                    <InputField
                      label="Title"
                      value={videoTitle}
                      ref={videoTitleRef}
                      name="videoTitle"
                      type="text"
                      placeholder="Enter video title here"
                      onChange={handleVideoTitle}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                      styleClass="br_10 mxwp_90"
                    />
                  </div>
                  <div className="col-sm-10 col-md-10 mb_20">
                    <InputField
                      label="Description"
                      value={description}
                      type="textarea"
                      placeholder="Enter description here"
                      rows="2"
                      cols="20"
                      onChange={handleDescription}
                      ref={descriptionRef}
                      styleClass="br_10 mxwp_90"
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Thumbnail image (image-size 720X405)</label>
                    <input
                      className="form-control f_14 l_height_38 br_10 mxwp_80"
                      type="file"
                      id="thumbnail1"
                      onChange={(event) =>
                        setThumbnail1(event.target.files[0])
                      }
                      name="thumbnail1"
                      required
                      accept=".jpg,.jpeg,.png"
                    />
                    {largeloader && (
                      <div class="clearfix text-center">
                        <div
                          class="spinner-border"
                          style={{ color: "#3f2783" }}
                          role="status"
                        >
                          <span class="visually-hidden">
                            Loading...
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Thumbnail image (image-size 480X270)</label>
                    <input
                      className="form-control f_14 l_height_38 br_10 mxwp_80"
                      type="file"
                      id="thumbnail2"
                      onChange={(event) =>
                        setThumbnail2(event.target.files[0])
                      }
                      name="thumbnail2"
                      required
                      accept=".jpg,.jpeg,.png"
                    />
                    {small && (
                      <div class="clearfix text-center">
                        <div
                          class="spinner-border"
                          role="status"
                          style={{ color: "#3f2783" }}
                        >
                          <span class="visually-hidden">
                            Loading...
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  {/* <div className="col-sm-5 col-md-5 mb_20">
                    <label>Video</label>
                    <ChunkUpload
                      load={load}
                      video_key={video_key}
                      setVideoload={setVideoload}
                      sendData={sendData}
                    />
                  </div> */}
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Tags</label>
                    <Multiselect
                      selectedValues={categoryId}
                      options={arr}
                      onSelect={(event) => selectOption(event)}
                      onRemove={(event) => selectOption(event)}
                      displayValue="name"
                      className="multi_select_main"
                    // showCheckbox
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Assign Channels</label>
                    <Multiselect
                      selectedValues={channelsId}
                      options={channelsData}
                      onSelect={(event) => selectOption1(event)}
                      onRemove={(event) => selectOption1(event)}
                      displayValue="name"
                      className="multi_select_main"
                      ref={multiselectRef}
                    // showCheckbox
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20 d_flex pt_30">
                    <label style={{ width: "25%" }}>Publish</label>
                    <Switch
                      name={`on-off-switch`}
                      tabIndex={1}
                      checked={enabled}
                      onChange={(e) => handleSwitchChange(enabled)}
                    />
                  </div>
                </div>
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <Button
                      onClick={handleContinue}
                      value="Submit"
                      enable={disble}
                      buttonStyle="btn_fill ms-4 clk_btn_disbled"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
}

export default EditVideoDetails;
