
const initialState = { 
    arr : ''
  }
  
  const languageData = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_LANGUAGE_DATA':
        return { ...state, arr: action.data };
  
  
  
      default:
        return state;
    }
  }
  
  export default languageData;