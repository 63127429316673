import { combineReducers } from "redux";
import userIdReducer from "./userIdReducer";
import userMailReducer from "./userMailReducer";
import adultKneeScoreReducer from "./adultKneeScoreReducer";
import loaderReducer from "./loaderReducer";
import loginData from "./loginData";
import channels from "./channelsReducer";
import tags from './tagsReducer'
import noteRef from "./noteRef";
import apiMessageReducer from "./apiMessageReducer";
import apiMessageTypeReducer from "./apiMessageTypeReducer";
import accessTokens from "./accessTokens";
import platFormData from './platFormDataReducer'
import languageData from "./languageData";

const mainReducer = combineReducers({
  userDetails: userIdReducer,
  userMailDetails: userMailReducer,
  adultKneeScore: adultKneeScoreReducer,
  loaderReducer,
  loginData,
  channels,
  tags,
  noteRef,
  apiMessageReducer,
  apiMessageTypeReducer,
  accessTokens,
  platFormData,
  languageData
});

export default mainReducer;
