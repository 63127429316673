import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { useSearchParams } from "react-router-dom";
import UsersListTable from "./UsersListTable";
import SortDropdown from "../custom/sortdropdown/SortDropdown";
import SortDropDownAlphabet from "../custom/sortdropalphabet/SortDropDownAlphabet";
import "./Users.css";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


function Users() {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const [searchParams, setSearchParams] = useSearchParams();
  let searchData = searchParams.get("q");
  const [qData, setqData] = useState("");
  // console.log('search data ', qData)
  let searchType = searchParams.get("search");

  const [isLoading, setIsLoading] = useState(true);
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  // const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const [hcpData, setData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  // const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  // const [status, setStatus] = useState("");
  const [sort, setSort] = useState("last_created");
  const [loader1, setloader1] = useState(false);
  const [lastTypingTime, setLastTypingTime] = useState(null);

  const [status, setStatus] = useState(1);
  const [userOrgList, setUserOrgList] = useState([]);
  const [userOrg, setUserOrg] = useState([]);

  const [offset, setOffset] = useState(25);
  const [limit, setLimit] = useState(25);
  const [searchEnable, setsearchEnable] = useState("N");
  const [startDate, setStartDate] = useState("");
  const [sortType, setSortType] = useState("last_created");
  const [totaldata, setTotaldata] = useState("");
  const [selectedOrg, setSelectedOrg] = useState([]);

  useEffect(() => {
    setqData(searchData);
    setsearchEnable(searchType);
  }, [searchData, searchType]);

  const removeQueryParams = () => {
    const param = searchParams.get("q");

    if (param) {
      // 👇️ delete each query param
      searchParams.delete("q");
      searchParams.delete("search");

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  };

  const handleReset = (e) => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setUserOrg([]);
    setSelectedOrg([]);

  };
  const advsearchToFirstName = (event) => {
    setLastTypingTime(new Date().getTime())
    setFirstName(event);
    removeQueryParams();
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(25);
  };
  const inviteEmailChange = (e) => {
    console.log("test", e);
  };
  const advsearchToMail = (event) => {
    setLastTypingTime(new Date().getTime())
    setEmail(event);
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(25);
  };
  const handleDob = (event) => {
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(25);
    setStartDate(event);
  };
  const handleUserOrg = (event,value) => {
    if (value) {
      setSelectedOrg(value);
      setUserOrg(value.value)
    } else {
      setUserOrg("")
      setSelectedOrg([])
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const getData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/getAllUsers/${login.ak_id}?limit=${limit}&offset=0&role=${role}&email=${email}&username=${firstName}&sort=${sort}&akou_id=${userOrg}`
        );
        if (response.status === 200) {
          setloader1(true)
          setData(response.data.data);
          setTotaldata(response.data.totalCount)
        }
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };

    getData()
  }, [sort, userOrg, dataRefe]);


  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const getData = async () => {
          try {
            const response = await axiosInstance.current.get(
              `extorg/admin/getAllUsers/${login.ak_id}?limit=${limit}&offset=0&role=${role}&email=${email}&username=${firstName}&sort=${sort}&akou_id=${userOrg}`
            );
            if (response.status === 200) {
              setloader1(true)
              setData(response.data.data);
              setTotaldata(response.data.totalCount)
            }
          } catch (error) {
            console.log("An error occurred while fetching data.");
          }
        };

        getData();

      }, 1000);
      return () => clearTimeout(timer)
    }
  }, [firstName, email])













  const addMoreData = async () => {
    setOffset(offset + 25);
    try {
      const response = await axiosInstance.current.get(
        `extorg/admin/getAllUsers/${login.ak_id}?limit=${limit}&offset=${offset}&role=${role}&email=${email}&username=${firstName}&sort=${sort}&akou_id=${userOrg}`
      );
      const resdata = response.data.data;
      console.log("data", response.data.data);
      setTimeout(() => {
        setData(hcpData.concat(resdata));
      }, 500);
    } catch (error) {
      console.log("An error occurred while fetching data.");
    }
  };
  const fetchMoreData = () => {
    if (searchEnable === "Y") {
      // addMoreDataSearch();
    } else {
      addMoreData();
    }
  };
  const handleSort = (e) => {
    setSort(e);
  };

  useEffect(() => {
    const getOrgList = async () => {
      try {
        const response = await axiosInstance.current.get(
          `/extorg/admin/orgs?ak_id=${login.ak_id}`
        );
        const newData = response.data.data.map((item) => ({
          value: item.id,
          label: item.o_nm,
          plan_id: item.plan_id
        }));
        setUserOrgList(newData);
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    getOrgList();
  }, []);


  useEffect(() => {
    if (firstName.length === 0 || email.length === 0 || userOrg.length === 0) {
      setOffset(25)
    }


  }, [firstName, email, userOrg])

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="hcp__list mb_100">
            <div className="panel mb_30">
              <div className="panel-heading  pannel_pd">
                <div className="row">
                  <div className="col-sm-6 col-md-6 pt_30">
                    <h4>User Details</h4>
                  </div>
                  <div className="col-sm-6 col-md-6 pt_30 text-right">
                    <Button
                      onClick={() => navigate("/create-user")}
                      value="+ Add User"
                      buttonStyle="btn_fill"
                    />
                  </div>
                </div>
              </div>
              <div className="panel-body p_25">
                <>
                  <div className="adv_search_sec">
                    <h4 className="mb_30">{"Advanced Search"}</h4>
                    {/* <form onSubmit={handleSubmit}> */}

                    <div className="row">
                      <div className="col-sm-6 col-md-3">
                        <InputField
                          label="User Name"
                          value={firstName}
                          type="text"
                          name="firstName"
                          placeholder="User Name"
                          onChange={advsearchToFirstName}
                        />
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <InputField
                          label="Email"
                          value={email}
                          type="text"
                          placeholder="Email Address"
                          onChange={(e) => advsearchToMail(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-3">
                        {/* <Dropdown
                          data={userOrgList}
                          label="Organization"
                          value={userOrg}
                          onChange={handleUserOrg}
                          placeholder="Select"
                          styleClass="mxw_100 mb_20"
                        /> */}
                        <label>Organziation</label>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          className="w-100"
                          style={{ borderRadius: "20px" }}
                          options={userOrgList}
                          getOptionLabel={(option) => `${option.label}`}
                          onChange={handleUserOrg}
                          renderInput={(params) => <TextField placeholder="Select Organization"
                            InputLabelProps={{
                              shrink: false,
                            }}
                            sx={{
                              "& ::placeholder": {
                                color: "#3f2783",
                                fontSize:"0.9rem",
                                lineHeight:"1.9",
                                fontWeight:500
                                // Change this to the desired color
                              },
                            }}
                            {...params} label="" />}
                        />
                      </div>
                      <div className="col-sm-6 col-md-3 pt_30">
                        <Button
                          onClick={handleReset}
                          value="Reset"
                          buttonStyle="btn_fill mr_20"
                        />
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6 col-md-6">
                      <h4 className="mb-3 ml_25">{"List of Users"}</h4>

                      <p className="mb_30 ml_25">
                        {totaldata} {totaldata > 1 ? "Results" : "Result"}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <SortDropdown onChange={handleSort} alp={false} />
                      {/* <SortDropDownAlphabet onChange={handleSort} /> */}
                    </div>
                  </div>

                  {!loader1 ? <div className="no_data">
                    loading...
                  </div> : (
                    <>
                      {
                        hcpData.length > 0 ? (
                          <UsersListTable
                            hcpData={hcpData}
                            updatePagination={fetchMoreData}
                            totaldata={totaldata}

                          />
                        ) : (
                          <div className="no_data">No data found</div>
                        )}

                    </>
                  )
                  }



                  {/* {hcpData.length > 0 ? (
                    <UsersListTable
                      hcpData={hcpData}
                      updatePagination={fetchMoreData}
                    />
                  ) : (
                    <div className="no_data">No Data Found</div>
                  )} */}
                </>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Users;
