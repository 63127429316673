import React, { useState, useSelector } from "react";
import { useTable } from "react-table";
import TableSpinner from "../custom/spinner/TableSpinner";
import TableActionItems from "../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";

function ChannelsListTable({ channelsData, updatePagination,setRefresh,refresh,orgList ,totalData}) {
  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  //   const [doctorID, setDoctorID] = useState(login.ing_ak_id);
  const actionData = {
    view_url: "patient-view",
    edit_url: "edit-patient-details",
    delete_url: "",
  };
  //   function handleEdit(row) {
  //     console.log(row);
  //   }
  const data = channelsData;

  const columns = React.useMemo(
    () => [
      {
        Header: "S No.",
        accessor: "s_no",
      },
      {
        Header: "Channel Name",
        accessor: "name",
      },
      {
        Header: "Audience",
        accessor: "user_type",
        Cell: ({ value }) => (value ? value : "Any user"),

      },
      {
        Header: "Organization",
        accessor: "org_name",
        Cell: ({ value }) => (value ? value : "Not join yet"),
      },
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          <span>
            <TableActionItems
              viewAction="false"
              editAction="false"
              editActionPopup="true"
              feedbackAction="false"
              deleteAction="true"
              arrowAction="false"
              rowName={"patientID"}
              rowId={row.row.original.ea_akpt_id}
              rowData={actionData}
              rowType="channels"
              notetitle={row.row.original.ea_name}
              rowOrgId={row.row.original.ea_akpt_id}
              orgId={row.row.original.ea_org_id}
              deleteChannelId={row.row.original.id}
              type="3"
              setRefresh={setRefresh}
              refresh={refresh}
              channelId={row.row.original.id}
              channelname={row.row.original.name}
              role={row.row.original.role}
              all={row.row.original.all}
              organization_id={row.row.original.org_id===null ? "" :row.row.original.org_id}
              orglist={orgList}
            />
          </span>
        ),
      },
    ],
    [orgList?.length]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
 


  return (
    <>
      <div className="tableContainer" id="rpScrollableDiv" >
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDiv"
          // loader={<TableSpinner />}
          loader={ channelsData.length >= 25 && channelsData.length !== totalData  ? <TableSpinner /> : null}

        >
          <table {...getTableProps()} className="custom-tbl">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default ChannelsListTable;
