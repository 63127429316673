// // import { useEffect, useRef } from 'react';
// // import axios from 'axios';
// // import { useKeycloak } from '@react-keycloak/web';
// // const useAxios = (baseURL) => {
// //   const axiosInstance = useRef();
// //   const { keycloak, initialized } = useKeycloak();
// //   const kcToken = keycloak.token;
// //   useEffect(() => {
// //     axiosInstance.current = axios.create({
// //       baseURL: process.env.REACT_APP_APIURL,
// //       headers: {
// //         Authorization: initialized ? `Bearer ${kcToken}` : undefined,
// //       },
// //     });
// //     return () => {
// //       axiosInstance.current = undefined;
// //     };
// //   }, [baseURL, initialized, keycloak]);
// //   useEffect(() => {
// //     const refreshToken = async () => {
// //       if (initialized && keycloak.authenticated) {
// //         const refreshed = await keycloak.updateToken(-1);
// //         if (refreshed) {
// //           console.log('Token has been refreshed');
// //         } else {
// //           console.log('Token refresh failed');
// //         }
// //       }
// //     };
// //     refreshToken();
// //   }, [initialized, keycloak]);
// //   return axiosInstance;
// // };
// // export default useAxios;
// import { useEffect, useRef } from "react";
// import axios from "axios";
// import { useKeycloak } from "@react-keycloak/web";
// const useAxios = (baseURL) => {
//   const interval = setInterval(5, 60 * 1000);
//   const axiosInstance = useRef();
//   const { keycloak, initialized } = useKeycloak();
//   useEffect(() => {
// //  setInterval(() => {
//     // const refresh = async () => {
//     //   if (initialized && keycloak.authenticated) {
//     //     const refreshed = await keycloak.updateToken(-1);
//     //     if (refreshed) {
//     //       console.log("Token has been refreshed");
//     //     } else {
//     //       console.log("Token refresh failed");
//     //     }
//     //   }
//     // };
//     const createAxiosInstance = () => {
//       axiosInstance.current = axios.create({
//         baseURL: process.env.REACT_APP_ORG_URL,
//         headers: {
//           Authorization:
//             initialized && keycloak.token
//               ? `Bearer ${keycloak.token}`
//               : undefined,
//         },
//       });
//       // axiosInstance.current.interceptors.request.use(async (config) => {
//       //   if (initialized && keycloak.authenticated) {
//       //     const refreshed = await keycloak.updateToken(-1);
//       //     if (refreshed) {
//       //       console.log("Token has been refreshed");
//       //       config.headers.Authorization = `Bearer ${keycloak.token}`;
//       //     } else {
//       //       console.log("Token refresh failed");
//       //     }
//       //   }
//       //   return config;
//       // });
//     };
//     createAxiosInstance();
//     // const interval = setInterval(refresh, 60 * 1000);
//     // return () => {
//     //   clearInterval(interval);
//     //   axiosInstance.current = null;
//     // };
//   // }, 30000); // Adjust the delay (in milliseconds) as needed
    
 
// },[interval]);
//   return axiosInstance;
// };
// export default useAxios;



import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { accessToken } from "./redux/actions";
import { useSelector, useDispatch } from "react-redux";
const useAxios = (baseURL) => {
  const axiosInstance = useRef();
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const tokens = useSelector((state) => state.mainReducer.accessTokens.arr);
  
// let tc =localStorage.getItem("token");
  useEffect(() => {
  
    const createAxiosInstance = () => {
      
      axiosInstance.current = axios.create({
        baseURL: process.env.REACT_APP_ORG_URL,
        headers: {
          Authorization:
            initialized && keycloak.token
              ? `Bearer ${ typeof tokens !=="object" ? tokens : keycloak.token}`
              : undefined,
        },
      });
    };
   

    // Check for token expiration and refresh
    const checkTokenExpiration = async () => {
      const token = keycloak.token;
      // console.log(token, "tokentoken");
      dispatch(accessToken(token))
      if (token) {
        const decodedToken = keycloak.tokenParsed;
        const currentTime = Math.floor(Date.now() / 1000);
        const tokenExpirationThreshold = 300; // 5 minutes before expiration

        if (decodedToken.exp - currentTime < tokenExpirationThreshold) {
          // Token is about to expire or has already expired
          if (!isRefreshing) {
            setIsRefreshing(true);
            try {
              await keycloak.updateToken(); // Refresh the token
              console.log("refresh token");
              setIsRefreshing(false);
              localStorage.setItem("token", token);
              dispatch(accessToken(token))
              createAxiosInstance();
            } catch (error) {
              console.error("Error refreshing token:", error);
              setIsRefreshing(false);
            }
          }
        }
      }
    };


    const handleRequestError = (error) => {

      if (error.response ) {
        
        if(error.response.status === 401 || error.response.status === 404 ){
     

          setIsRefreshing(false);
          checkTokenExpiration();
        }
      } else {
        // Handle other error cases here.
      }
      throw error; // Rethrow the error to propagate it to the caller.
    };
    // createAxiosInstance();
    axiosInstance.current?.interceptors.response.use(
      (response) => response,
      (error) => {
        return Promise.reject(handleRequestError(error));
      }
    );

    
    createAxiosInstance();
    // Set up an interval to periodically check token expiration
    const tokenCheckInterval = setInterval(checkTokenExpiration, 60 * 1000); // Check every minute

    return () => {
      clearInterval(tokenCheckInterval);
    };
  }, [initialized, isRefreshing, keycloak,tokens]);

  return axiosInstance;
};

export default useAxios;