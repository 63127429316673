import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import "./TopHeader.css";
import leftlogo from "../../images/akunah-hr-logo.svg";
import { s3UrlLink } from "../../utilities/S3Url";
import useTranslation from "../customHooks/translations";
import backArrow from "../../images/head-back-arr.svg";
import chatIcon from "../../images/chat-icon.svg";
import notificationIcon from "../../images/notification.svg";
import signOutIcon from "../../images/Sign out.svg";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
import { Validators } from "../../utilities/Validator";
import Modal from "react-bootstrap/Modal";
import LanguageHandler from "../LanguageHandler";
import useAxiosUpload from "../../axiosinstanceUpload";
import useAxios from "../../axiosinstance";
import { useSelector, useDispatch } from "react-redux";
import { channeldata, setChannelLists } from "../../redux/actions/index";
import { tagData } from '../../redux/actions/index'
import { loginData } from "../../redux/actions/index";
import {platFormData} from '../../redux/actions/index'
import { languageData } from "../../redux/actions/index";


const TopHeader = ({ chk }) => {
  const { keycloak, initialized } = useKeycloak();
  const axiosInstance = useAxios();
  const dispatch = useDispatch()
  //create initial menuCollapse state using useState hook
  const translation = useTranslation();
  const axiosInstanceUpload = useAxiosUpload();
  const [isOpened, setIsOpened] = useState(false);
  const [chatPopup, setChatPopup] = useState(false);
  const [editProfileShow, setEditProfileShow] = useState(false);
  const [needHelpShow, setNeedHelpShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [modalOpenSmall, setModalOpenSmall] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();

  const handleClose = () => setEditProfileShow(false);
  const handleShow = () => setEditProfileShow(true);
  const handleNeedHelpClose = () => setNeedHelpShow(false);
  const handleNeedHelpShow = () => setNeedHelpShow(true);
  const logout_url = window.location.origin;
  const akTokenOrg = localStorage.getItem("AKTOKENORG")
  const key = useSelector((state) => state.mainReducer.channels.key)
  const [keyword, setKeyword] = useState("")

  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const [doctor] = useState(login.ak_id);
  const handleFirstName = (e) => {
    setFirstName(e);
  };
  const handleLastName = (e) => {
    setLastName(e);
  };
  const handlePhone = (e) => {
    setPhone(e);
  };
  const handleEmail = (e) => {
    setEmail(e);
  };
  const handleTimeZone = (e) => {
    setTimeZone(e);
  };
  const closeModalSmall = () => {
    setModalOpenSmall(false);
  };
  const userIconClick = () => {
    isOpened ? setIsOpened(false) : setIsOpened(true);
    closeModalSmall();
  };
  const handleChatPopup = () => {
    setNeedHelpShow(false);
    setChatPopup(!chatPopup);
  };
  const handleLogout = () => {
    localStorage.removeItem("AKTOKENORG");
    keycloak.logout({ redirectUri: logout_url });
  };


  const channelList = async () => {
    const response = await axiosInstance.current.get(
      `extorg/admin/getChannelList?ak_id=${akTokenOrg}&keyword=${keyword}&orgKey=${""}&meta=${2}`
    );
    dispatch(channeldata(response.data.data))
    dispatch(setChannelLists(response.data.data2))
  };


  useEffect(() => {
    channelList()
    // getTagsList()
  }, [akTokenOrg, keyword])




  useEffect(() => {
    if (key) {
      setKeyword(key)
    } else {
      setKeyword("")
    }
  }, [key, keyword])

  const handleConnectMe = async () => {
    try {
  
      const result = await axiosInstance.current.get(
        `extorg/org/userProfile?ak_id=${login.ak_id}`
      );
      if (result.status == "200") {
        if (result.data.status) {
          dispatch(loginData(result.data.data));
          localStorage.setItem("AKTOKENORG", login.ak_id);
          // redirectToHome();
        } else {
          // redirectToError();
        }
      }
    } catch (err) {
      setMessage(err.message || "Unexpected Error!");
      console.log(err.Messages);
      setMessageType("error");
    } finally {
      console.log("api response finally");
    }
  };

  function onFileUpload(e) {
    const formData = new FormData();
    formData.append("profile_pic", selectedImage);
    // console.log(Object.keys(formData).length);
    // if (Object.keys(formData).length !== 0)
    axiosInstanceUpload.current
      .post(
        `extglobal/updateprofile?ak_id=${login.ak_id}`,
        formData
      )
      .then((response) => {
        if (response) {
          setSelectedImage(response.data.url);
          handleConnectMe();
        }
      })
      .catch((err) => {
        // setMessage(err.response.data.message);
        // setMessageType("error");
      });
  }

  useEffect(() => {
    if (typeof selectedImage === "object") {
      setMessage(false);

      onFileUpload();
    }
  }, [selectedImage]);


  // const getTagsList = async () => {
  //   try {
  //     const response = await axiosInstance.current.get(
  //       `extorg/admin/listOfTags?platform=${""}&limit=${100}&offset=${0}`
  //     );
  //     dispatch(tagData(response.data.data))
  //   } catch (error) {
  //     console.log("error", error)
  //   }
  // }


  const [platData, setPlatData] = useState([]);


  useEffect(() => {
    const getPlatFormData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/getPlatform`
        );
    
        // setPlatFormData(response.data.data);
        let data = response.data.data.map((item) => {
          return {
            value: item.plat_id,
            label: item.name
          }
        })
        setPlatData(data)
        dispatch(platFormData(data))
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    getPlatFormData();
  }, []);



  const getallLangugaes = async()=>{
  
    try {

      const response = await axiosInstance.current.get(
        `extorg/admin/get_languages?ak_id=${login.ak_id}`
      );
      if(response.status===200){
        dispatch(languageData(response.data.data));
      }
      
    } catch (error) {
      dispatch(languageData([]));
      console.log(error)
    }
  }




  useEffect(() => {
    if(login.ak_id){
    getallLangugaes()
    }
  }, [login.ak_id])



  return (
    <>
      <div className={chk ? "App" : " "}>
        <div className="top__header">
          <div className="nav_inner_left">
            <Link to="/" className="leftLogo">
              <img src={leftlogo} className="left-logo" alt="logo" />
            </Link>
          </div>
          <div className="nav_inner_search"></div>
          {/* <Button
                    value="Logout"
                    buttonStyle="btn_outline"
                    width="104px"
                    onClick={() => handleLogout()}
                  /> */}
          <div className="nav_inner_right">
            <div className="right_user_menu">
              <ul className="list-inline">
                <li className="chat-menu list-inline-item">

                </li>
                {
                  login.length === 0 ? (

                    <>
                      <li className="chat-menu list-inline-item">
                        {/* <LanguageHandler langData={langData}></LanguageHandler> */}
                      </li>
                      <li className="list-inline-item use_lett">
                        <Button
                          value={"Logout"}
                          buttonStyle="btn_outline w-auto"
                          width="104px"
                          onClick={() => handleLogout()}
                        />
                      </li>
                    </>
                  ) :
                    <>
                      <li className="chat-menu list-inline-item">
                        {/* <LanguageHandler langData={langData}></LanguageHandler> */}
                      </li>
                      <li className="list-inline-item use_lett">
                        <label
                          onClick={() => {
                            userIconClick();
                          }}
                          className="user-letter text-uppercase"
                        >
                          {!login.image || login.image === "" ? (
                          // If profile image is undefined or empty, display initials
                            login.firstname.slice(0, 1) + login.lastname.slice(0, 1)
                          ) : (
                            // If profile image is not empty, display the image
                            <img
                              src={s3UrlLink(login.image)}
                              alt={login.firstname}
                            />
                          )}
                        </label>
                      </li>
                    </>
                }


                {/* <Button
                  value={translation.logout}
                  buttonStyle="btn_outline w-auto"
                  width="104px"
                  onClick={() => handleLogout()}
                /> */}
              </ul>
            </div>
          </div>
        </div>

        {isOpened && (
          <div className="sidenav_right">
            <div className="rightnavInner">
              <div className="row">
                <div className="col-sm-6">
                  <h6 className="left_title">{translation.myProfile}</h6>
                </div>
                <div className="col-sm-6">
                  <span
                    onClick={() => {
                      userIconClick();
                    }}
                    className="close_btn"
                  >
                    &#10006;
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="pf_details">
                    <label className="user-letter large text-uppercase">
                      {!login.image || login.image === "" ? (
                        // If profile image is undefined or empty, display initials
                        login.firstname.slice(0, 1) + login.lastname.slice(0, 1)
                      ) : (
                        // If profile image is not empty, display the image
                        <img
                          src={s3UrlLink(login.image)}
                          alt={login.firstname}
                        />
                      )}
                    </label>
                    <h4 className="text-capitalize">
                      {login.firstname + " " + login.lastname}
                    </h4>
                    <label
                      for="profileImage"
                      className="btn_outline w-50"
                      onClick={""}
                      style={{ lineHeight: "37px" }}
                    >
                      {"Upload an Image"}
                    </label>
                    <input
                      type="file"
                      name="profileImage"
                      id="profileImage"
                      hidden
                      style={{ display: "none" }}
                      onChange={(event) => {
                        setSelectedImage(event.target.files[0]);
                      }}
                      accept=".jpg,.jpeg,.png"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-4">
                  <p className="pf_left_btext">
                    <strong>{translation.Name}:</strong>
                  </p>
                </div>
                <div className="col-sm-8">
                  <p>{login.firstname + " " + login.lastname}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <p className="pf_left_btext">
                    <strong>{"Akunah ID"}:</strong>
                  </p>
                </div>
                <div className="col-sm-8">
                  <p>{login.ak_id}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <p className="pf_left_btext">
                    <strong>{"Phone"}:</strong>
                  </p>
                </div>
                <div className="col-sm-8">
                  <p>{login.mobile}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <p className="pf_left_btext">
                    <strong>{"Email"}:</strong>
                  </p>
                </div>
                <div className="col-sm-8">
                  <p>{login.email}</p>
                </div>
              </div>

              <span className="signout_btn" onClick={() => handleLogout()}>
                <img src={signOutIcon} alt="icon" />
                {"Logout"}
              </span>

              {/* <span className="switch_btn" onClick={() => handlerSwitch()}>
                <img
                  src={switchImg}
                  alt="icon"
                />
                Switch profile
              </span> */}
            </div>
          </div>
        )}
      </div>
      
    </>
  );
};

export default TopHeader;
