import React, { useState, useEffect, useRef } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { useSearchParams } from "react-router-dom";
import { Validators } from "../../utilities/Validator";
import ChannelsListTable from "./ChannelsListTable";
import "./Channels.css";
import { channelKey, channeldata } from "../../redux/actions/index";
import { useSelector, useDispatch } from "react-redux";
import DropDownOrgList from "../custom/dropdown/DropDownOrgList";
import Message from "../custom/toster/Message";
import { noteRefs } from "../../redux/actions";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function Channels() {
  let result = []
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const axiosInstance = useAxios();
  const [searchParams, setSearchParams] = useSearchParams();
  let searchData = searchParams.get("q");
  const [qData, setqData] = useState("");
  // console.log('search data ', qData)
  let searchType = searchParams.get("search");
  const [orgModel, setOrgModel] = useState("");
  const [roleModel, setRoleModel] = useState("0");
  const [publishModel, setPublishModel] = useState("");
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState("")
  const [selectedOrg, setSelectedOrg] = useState([]);

  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  const login = "";
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);

  // const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  // const channels = useSelector((state)=>state.mainReducer.channels.channelsData)
  const profile = useSelector((state) => state.mainReducer.loginData.arr);
  const [channelsData, setData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  // const [status, setStatus] = useState("");
  const [searchEnable, setsearchEnable] = useState("N");
  const [startDate, setStartDate] = useState("");
  // const [startDate, setStartDate] = useState(new Date());
  const [disble, setDisable] = useState(false)

  const [offset, setOffset] = useState(25);
  const [limit, setLimit] = useState(25);
  const [orgList, setOrgList] = useState([])
  const [sortType, setSortType] = useState("last_created");
  const [totaldata, setTotaldata] = useState("");
  const [channelName, setChannelName] = useState("");
  const [showAddChannel, setShowAddChannel] = useState(false);
  const [orgapiData, setOrgData] = useState([]);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  const [patientErr, setpatienterr] = useState(false)
  const[err,setErr]=useState(false)
  const [roleApiData, setRoleApiData] = useState([
    { value: "0", label: "Any User" },
    { value: "3", label: "Only HCP" },
    { value: "1", label: "Only Patient" },
  ]);
  const [publishApiData, setPublishApiData] = useState([
    { value: true, label: "All Users" },
    { value: false, label: "Selected Users" },
    // { value: "3", label: "Selected Patients" },
  ]);
  const [allUsersList, setAllUsersList] = useState([]);
  const [allSelectedUsers, setAllSelectedUsers] = useState([]);
  const [loader1, setloader1] = useState(false);
  const [lastTypingTime, setLastTypingTime] = useState(null);

  const handleCloseChannel = () => {
    setChannelName("")
    setOrgModel("")
    setRoleModel("")
    setPublishModel("")
    setAllSelectedUsers([])
    setSelectedOption([])
    setShowAddChannel(false)
    setErr(false)

  }


  const orgModelRef = useRef();
  const channelNameRef = useRef();
  const roleModelRef = useRef();
  const publishModelRef = useRef();
  const akTokenOrg = localStorage.getItem("AKTOKENORG")
  useEffect(() => {
    setqData(searchData);
    setsearchEnable(searchType);
  }, [searchData, searchType]);

  const removeQueryParams = () => {
    const param = searchParams.get("q");

    if (param) {
      // 👇️ delete each query param
      searchParams.delete("q");
      searchParams.delete("search");

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  };
  const handleOrgModle = (event, value) => {
    if (value) {
      setErr(false)
      setSelectedOrg(value);
      setOrgModel(value.id)
      console.log(value, "valueeeeeeeeeee");
    } else {
      setErr(true)
      setOrgModel("")
      setSelectedOrg([])
    }
    setOffset(25)
    setSelectedOption([])
    setAllUsersList([])
  };

  const handleRoleModle = (e) => {
    setRoleModel(e);
    setAllUsersList([])
    setSelectedOption([])
  };


  const handlePublishModle = (e) => {
    setPublishModel(e);

  };

  const handleSelect = (event, newValue) => {
    setSelectedOption(newValue);
  };
  selectedOption.map((ele) => {
    result.push(ele.value)
  })







  const handleOnSearch = (value, results) => {
    // console.log(value);
    if (value.length > 2) {
      setTimeout(() => {
        axiosInstance.current.get(`extorg/admin/searchUser?ak_id=${akTokenOrg}&key=${value}&type=${Number(roleModel)}&akou_id=${orgModel}`)
          .then((response) => {
            const options = response.data.data.map((item) => {
              return {
                value: item.value,
                label: item.label

              };
            });
            setAllUsersList(options);
          })
          .catch((error) => {

            setAllUsersList([]);
          });
      }, 2000);

    } else {
      setAllUsersList([]);
    }
  };



  const handleReset = (e) => {
    setFirstName("");
    setLastName("");
    setStartDate("");
    setEmail("");
    setOrgName("")
    if (searchType === "Y") {
      setsearchEnable("Y");
    }
  };
  const advsearchToFirstName = (event) => {
    setLastTypingTime(new Date().getTime())
    setFirstName(event);
    dispatch(channelKey(firstName))
    dispatch({ type: "GET_CHANNEL_KEYWORD" })
    removeQueryParams();
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(25);
  };


  const advsearchToOrgName = (event) => {
    setLastTypingTime(new Date().getTime())
    setOrgName(event);

  };



  const advsearchToMail = (event) => {
    setEmail(event);
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(25);
  };
  const handleDob = (event) => {
    if (event === "") {
      // setsearchEnable("Y");
    } else {
      setsearchEnable("N");
    }
    setOffset(25);
    setStartDate(event);
  };



  const handleSort = (e) => {
    setSortType(e);
  };
  const channelNameChange = (e) => {
    setChannelName(e.replace(/[\/,?,$,#,%,+,-,&,!,*@]/g, ''));
  };
  const createChannelModal = () => {
    setShowAddChannel(true);
  };



  const check = (data) => {
    if (data === false || data === "false") {
      return false
    } else {
      return true
    }

  }



  const handleInvRequest = async () => {

    if (profile[0].type === 6) {
      if (!channelName) {
        channelNameRef.current.errUpdate();
      }
      if (!roleModel) {
        roleModelRef.current.errUpdate();
      }
      if (!publishModel) {
        publishModelRef.current.errUpdate();
      }

      console.log("comming 1", publishModel)

      if (publishModel === false || publishModel === "false") {
        if (selectedOption.length === 0) {
          setpatienterr(true)
          return
        }
      }
      console.log("comming 2", publishModel, selectedOption)

      if (
        !channelName ||
        !roleModel ||
        !publishModel
      ) {

      }
      else {
        setDisable(true)
        let json = {
          org_id: orgModel || null,
          name: channelName,
          role_id: roleModel === "0" ? "" : Number(roleModel),
          all: check(publishModel),
          ak_id: result
        }
        console.log("json data", json)

        const response = await axiosInstance.current.post(
          `extorg/admin/createChannel?ak_id=${akTokenOrg}`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        }
        ).then((res) => {
          setMessage("Channel Created")
          setMessageType("success");
          setDisable(false)
          dispatch(noteRefs(!dataRefe))
          setChannelName("")
          setOrgModel("")
          setSelectedOrg([])
          setErr(false)
          setRoleModel("")
          setPublishModel("")
          setAllUsersList([])
          setSelectedOption([])
          setTimeout(() => {
            setMessage(false)
          }, 3000);
          setShowAddChannel(false);
        }).catch((err) => {
          console.log("eeeeeeeeeee", err)
          setMessage(err.response.data.message)
          setMessageType("error");
          setTimeout(() => {
            setMessage(false)
            setDisable(false)
          }, 2000);
        })


      }
    } else {
      if (!channelName) {
        channelNameRef.current.errUpdate();
      }
      if (!roleModel) {
        roleModelRef.current.errUpdate();
      }
      if (!publishModel) {
        publishModelRef.current.errUpdate();
      }
      if (!orgModel) {
        setErr(true)
        // orgModelRef.current.errUpdate()
      }

      console.log("comming 1", publishModel)

      if (publishModel === false || publishModel === "false") {
        if (selectedOption.length === 0) {
          setpatienterr(true)
          return
        }
      }
      console.log("comming 2", publishModel, selectedOption)

      if (
        !channelName ||
        !roleModel ||
        !publishModel ||
        !orgModel
      ) {

      }
      else {
        setDisable(true)
        let json = {
          org_id: orgModel || null,
          name: channelName,
          role_id: roleModel === "0" ? "" : Number(roleModel),
          all: check(publishModel),
          ak_id: result
        }
        console.log("json data", json)

        const response = await axiosInstance.current.post(
          `extorg/admin/createChannel?ak_id=${akTokenOrg}`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        }
        ).then((res) => {
          setMessage("Channel Created")
          setMessageType("success");
          setDisable(false)
          dispatch(noteRefs(!dataRefe))
          setChannelName("")
          setOrgModel("")
          setSelectedOrg([])
          setErr(false)
          setRoleModel("")
          setPublishModel("")
          setAllUsersList([])
          setSelectedOption([])
          setTimeout(() => {
            setMessage(false)
          }, 3000);
          setShowAddChannel(false);
        }).catch((err) => {
          console.log("eeeeeeeeeee", err)
          setMessage(err.response.data.message)
          setMessageType("error");
          setTimeout(() => {
            setMessage(false)
            setDisable(false)
          }, 2000);
        })
      }
    }
  }


  const handleAllUsersChange = (e) => {
    setAllSelectedUsers(e);


  };

  allSelectedUsers.map((ele) => {
    result.push(ele.label)
  })


  const channelList = async () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const response = await axiosInstance.current.get(
      `extorg/admin/getChannelList?ak_id=${akTokenOrg}&keyword=${firstName}&orgKey=${orgName}&limit=${limit}&offset=${0}&meta=${1}` //OrgAdd
    );
    // dispatch(channeldata(response.data.data))
    if (response.status === 200) {
      setloader1(true)
      setData(response.data.data)
      setTotalData(response.data.total_data1)
    }
  };



  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const channelList = async () => {
          try {
            const response = await axiosInstance.current.get(
              `extorg/admin/getChannelList?ak_id=${akTokenOrg}&keyword=${firstName}&orgKey=${orgName}&limit=${limit}&offset=${0}&meta=${1}`
            );
            if (response.status === 200) {
              setloader1(true)
              setData(response.data.data)
              setTotalData(response.data.total_data1)

            }
          } catch (error) {
            console.log("An error occurred while fetching data.");
          }
        };

        channelList();



      }, 1000);
      return () => clearTimeout(timer)
    }
  }, [firstName, orgName])













  const organizatinList = async () => {
    await axiosInstance.current.get(
      `extorg/admin/orgs?ak_id=${akTokenOrg}` //OrgAdd
    ).then((res) => {
      setOrgData(res.data.data)
    }).catch((err) => {
      console.log(err)
    })
  }


  useEffect(() => {
    channelList()
    setOffset(25)
  }, [dataRefe])

  useEffect(() => {
    organizatinList()
  }, [])



  const addMoreData = async () => {
    setOffset(offset + 25);
    const response = await axiosInstance.current.get(
      `extorg/admin/getChannelList?ak_id=${akTokenOrg}&keyword=${firstName}&orgKey=${orgName}&limit=${limit}&offset=${offset}&meta=${1}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setData(channelsData.concat(resdata));
    }, 500);
  };


  const fetchMoreData = () => {
    addMoreData()
  };

  console.log("for sys and org", orgapiData)


  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="channels__list mb_100">
            {message && <Message message={message} type={messageType} />}
            <div className="panel mb_30">
              <div className="panel-heading  pannel_pd">
                <div className="row">
                  <div className="col-sm-6 col-md-6 pt_30">
                    <h4>Akunah Med Channels</h4>
                  </div>
                  <div className="col-sm-6 col-md-6 pt_30 text-right">
                    <Button
                      onClick={createChannelModal}
                      value="+ Add Channel"
                      buttonStyle="btn_fill w_160"
                    />
                  </div>
                </div>
              </div>
              <div className="panel-body p_25">
                <>
                  <div className="adv_search_sec">
                    <h4 className="mb_30">{"Advanced Search"}</h4>
                    {/* <form onSubmit={handleSubmit}> */}

                    <div className="row">
                      <div className="col-sm-6 col-md-3">
                        <InputField
                          label="Channel Name"
                          value={firstName}
                          type="text"
                          name="firstName"
                          placeholder="Channel Name"
                          onChange={advsearchToFirstName}
                        />
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <InputField
                          label="Organization"
                          value={orgName}
                          type="text"
                          name="orgName"
                          placeholder="Organization Name"
                          onChange={advsearchToOrgName}
                        />
                      </div>
                      {/* <div className="col-sm-6 col-md-3">
                        <InputField
                          label="Email"
                          value={email}
                          type="text"
                          placeholder="Email Address"
                          onChange={(e) => advsearchToMail(e)}
                        />
                      </div> */}
                      <div className="col-sm-6 col-md-3 pt_30">
                        <Button
                          onClick={handleReset}
                          value="Reset"
                          buttonStyle="btn_fill mr_20"
                        />
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6 col-md-6">
                      <h4 className="mb-3 ml_25">{"List of Channels"}</h4>

                      <p className="mb_30 ml_25">
                        {totalData} {totalData > 1 ? "Results" : "Result"}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      {/* <SortDropdown onChange={handleSort} alp={true} /> */}
                    </div>
                  </div>
                  {!loader1 ? <div className="no_data">
                    loading...
                  </div> : (
                    <>
                      {
                        channelsData.length > 0 ? (
                          <ChannelsListTable
                            channelsData={channelsData}
                            updatePagination={fetchMoreData}
                            setRefresh={setRefresh}
                            refresh={refresh}
                            orgList={orgapiData}
                            totalData={totalData}
                          />
                        ) : (
                          <div className="no_data">No data found</div>
                        )}

                    </>
                  )
                  }



                  {/* {channelsData.length > 0 ? (
                    <ChannelsListTable
                      channelsData={channelsData}
                      updatePagination={fetchMoreData}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      orgList={orgapiData}
                    />
                  ) : (
                    <div className="no_data">No Data Found</div>
                  )} */}
                </>
              </div>
            </div>
          </div>
          <Modal
            show={showAddChannel}
            onHide={handleCloseChannel}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-md patient_notes_popup channels_popup"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center">Add Channel</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              {
                profile[0].type === 6 ? (
                  //   <DropDownOrgList
                  //   data={orgapiData}
                  //   label="Organization"
                  //   value={orgModel}
                  //   onChange={handleOrgModle}
                  //   placeholder="Select Organization"
                  //   styleClass="br_10 mxw_100 mb_20"
                  //   ref={orgModelRef}

                  // // validators={[
                  // //   {
                  // //     check: Validators.required,
                  // //     message: "This field is required",
                  // //   },
                  // // ]}
                  // />
                  <>
                    <label>Organziation</label>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      className="w-100"
                      style={{ borderRadius: "20px" }}
                      options={orgapiData}
                      getOptionLabel={(option) => `${option.o_nm}`}
                      onChange={handleOrgModle}
                      renderInput={(params) => <TextField placeholder="Select Organization"
                        InputLabelProps={{
                          shrink: false,
                        }}
                        sx={{
                          "& ::placeholder": {
                            color: "#3f2783", // Change this to the desired color
                          },
                        }}
                        {...params} label="" />}
                    />
                  </>

                ) : (
                  // <DropDownOrgList
                  //   data={orgapiData}
                  //   label="Organization"
                  //   value={orgModel}
                  //   onChange={handleOrgModle}
                  //   placeholder="Select Organization"
                  //   styleClass="br_10 mxw_100 mb_20"
                  //   ref={orgModelRef}

                  //   validators={[
                  //     {
                  //       check: Validators.required,
                  //       message: "This field is required",
                  //     },
                  //   ]}
                  // />

                  <>
                    <label>Organziation</label>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      className="w-100"
                      style={{ borderRadius: "20px" }}
                      options={orgapiData}
                      getOptionLabel={(option) => `${option.o_nm}`}
                      onChange={handleOrgModle}
                      renderInput={(params) => <TextField placeholder="Select channel"
                        InputLabelProps={{
                          shrink: false,
                        }}
                        sx={{
                          "& ::placeholder": {
                            color: "#3f2783", // Change this to the desired color
                          },
                        }}
                        {...params} label="" />}
                    />

                    {
                      selectedOrg.length===0 ? (
                        <>
                        {
                          err ? (
                            <span className="text-danger">This field is required</span>
                          ):""
                        }
                        </>
                      ):""
                    }
                  </>
                )
              }


              <InputField
                label="Name"
                ref={channelNameRef}
                value={channelName}
                type="text"
                placeholder="Enter channel here"
                styleClass="br_10 mxw_100 mb_20"
                onChange={channelNameChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
              <Dropdown
                data={roleApiData}
                label="Visible To"
                value={roleModel}
                onChange={handleRoleModle}
                placeholder="Select Role"
                styleClass="br_10 mxw_100 mb_20"
                ref={roleModelRef}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
              <Dropdown
                data={publishApiData}
                label="Assigned To"
                value={publishModel}
                onChange={handlePublishModle}
                placeholder="Select"
                styleClass="br_10 mxw_100 mb_20"
                ref={publishModelRef}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
              {publishModel === "false" ? (
                // <div className="col-md-12 col-sm-12 mb_10">

                //   <label>Users</label>
                //   {/* <MultiSelect
                //     options={allUsersList}
                //     value={allSelectedUsers}
                //     //   onSelect={(event) => selectOption(event)}
                //     //   onRemove={(event) => selectOption(event)}
                //     onChange={handleAllUsersChange}
                //     displayValue="title"
                //     className="multi_select_main"
                //   // showCheckbox
                //   /> */}


                // </div>
                <>
                  <label>Users</label>

                  <div className="mr_15">
                    <div className="row">
                      <div className="col-md-12 col-sm-12 mb_10  organization-drop">
                        <div className="dropdown_main_1">
                          <span className="white_shade"></span>
                          <Autocomplete
                            multiple
                            // freeSolo
                            id="free-solo-2-demo"
                            // disableClearable={true}
                            options={allUsersList}
                            value={selectedOption}
                            className="w-100"
                            onChange={handleSelect}
                            // disableClearable={disable}
                            renderInput={(params) => (
                              <TextField
                                placeholder={"Users Name"}
                                className=""
                                style={
                                  {
                                    // height: "52px",
                                    // border: "2px solid #7867a8",
                                    // borderColor: "#7867a8",
                                    // borderRadius: "7px",
                                    // backgroundColor: "white",
                                    // boxShadow: "none",
                                    // // hoverBackgroundColor: "#3f2783",
                                    // hoverColor: "#fff",
                                    // color: "black",
                                    // fontSize: "12px",
                                    // fontFamily: "Courier",
                                    iconColor: "#fff",
                                    // // lineColor: "black",
                                    // placeholderColor: "black",
                                    // clearIconMargin: "3px 8px 0 0",
                                  }
                                }
                                {...params}
                                onChange={(e) => handleOnSearch(e.target.value)}
                                // onKeyUp={(e) => handleOnSearch(e.target.value)}
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                }}
                              />
                            )}
                          />

                        </div>
                        {selectedOption.length === 0 && (
                          <>
                            {patientErr && (
                              <span className="text-danger mt-2">
                                This field is required
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <i style={{ color: "#3f2783" }}>* Search with minimum of three characters</i>
                </>


              ) : (
                ""
              )}
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "flex-start" }}>
              <Button
                onClick={handleCloseChannel}
                value="Cancel"
                buttonStyle="btn_nill mr_20"
              />
              <Button
                onClick={handleInvRequest}
                value="Save"
                width="120px"
                enable={disble}
                buttonStyle="btn_fill ms-4 clk_btn_disbled"
              />
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default Channels;
