import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import { Validators } from "../../utilities/Validator";
import { useDispatch, useSelector } from "react-redux";
import { noteRefs } from "../../redux/actions";



const ViewUsers = ({ showModal, hideModal, user_ak_id,
    user_first_name,
    user_last_name,
    user_email,
    user_type,
    user_organziation,
    user_status }) => {

    const axiosInstance = useAxios();
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState();





    return (
        <>

            {message && <Message message={message} type={messageType} />}


            <Modal
                show={showModal}
                onHide={hideModal}
                className={``}
                centered
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-md patient_notes_popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">View User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">

                    <div className="row">
                        <div className="col-md-8">
                            <div className="d-flex">
                                <label>AK ID :</label>
                                <p className="ms-3">{user_ak_id}</p>
                            </div>
                            <div className="d-flex">
                                <label>User Name:</label>
                                <p className="ms-3">{user_first_name + user_last_name}</p>
                            </div>
                            <div className="d-flex">
                                <label>Email:</label>
                                <p className="ms-3">{user_email}</p>
                            </div>
                            <div className="d-flex">
                                <label>Type:</label>
                                {
                                    user_type===undefined ? (
                                        <p className="ms-3">Secretary</p>
                                    ):(
                                        <p className="ms-3">{user_type}</p>
                                    )
                                }
                             
                            </div>
                            <div className="d-flex">
                                <label>Organziation:</label>
                                <p className="ms-3">
                                    {user_organziation === undefined ? "N/A" : user_organziation.join(' , ')}
                                </p>
                            </div>

                            <div className="d-flex">
                                <label>Status:</label>
                                <p className="ms-3">{user_status === 1 ? "Active" : "Deactive"}</p>
                            </div>
                        </div>





                    </div>


                </Modal.Body>

            </Modal>


        </>
    )
}

export default ViewUsers
