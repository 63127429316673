import React, { useState, useEffect, useRef, useMemo } from "react";
// import {  useNavigate } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
import { Validators, spacialCharReplace } from "../../utilities/Validator";
import Button from "../custom/button/Button";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import ChunkUpload from "../custom/chunk-upload/ChunkUpload";
import Switch from "../custom/switch/Switch";
import { useSelector, useDispatch } from "react-redux";
import useAxiosUpload from "../../axiosinstanceUpload";
import { noteRefs } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import DropdownOrg from "../custom/dropdown/DropdownOrg";


function CreateVideo({ toNextTab, toData }) {
  const [isLoading, setIsLoading] = useState(true);
  const axiosInstance = useAxios();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const axiosInstanceUpload = useAxiosUpload();
  const akTokenOrg = localStorage.getItem("AKTOKENORG")

  const [videoTitle, setVideoTitle] = useState("");
  const [video_key, setTimes] = useState("");
  const [type_id, setTypeId] = useState("1");
  const [lastName, setLastName] = useState("");
  const [formVaild, setFormVaild] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [load, setVideoload] = useState(false);
  const [tagsData, setTagsData] = useState([]);
  const [thumbnail1, setThumbnail1] = useState(null);
  const [thumbnail2, setThumbnail2] = useState(null);
  const [fileSize, setfileSize] = useState('');
  const [enabled, setEnabled] = useState(0)

  const [large, setLarge] = useState("");
  const [medium, setMedium] = useState("");
  const [small, setSmall] = useState(false);
  const [video, setVideo] = useState(null);

  const [largeloader, setLargeloader] = useState(false);
  const [categoryId, setCategory] = useState([]);
  const [channeId, setChannelId] = useState([]);

  const [isChecked, setIsChecked] = useState(false);
  const [description, setDescription] = useState("");
  const [allOrgList, setAllOrgList] = useState([]);
  const [allSelectOrg, setAllSelectOrg] = useState("");
  const profile = useSelector((state) => state.mainReducer.loginData.arr);
  // const channels = useSelector((state)=>state.mainReducer.channels.channelsData)
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const orgchannels = useSelector((state) => state.mainReducer.channels.org_channel)
  const lastNameRef = useRef();
  const descriptionRef = useRef();
  const multiselectRef = useRef()
  const allOrgRef = useRef();
  const videoTitleRef = useRef()
  const [categortyData, setCategoryData] = useState([]);
  const [disble, setDisable] = useState(false)

  // const navigate = useNavigate();-
  let sessionId = localStorage.getItem("sessionId")

  const handleVideoTitle = (e, err) => {
    // setFirstNameErr(err);
    setVideoTitle(spacialCharReplace(e));
    // setFormVaild(e.error ? e.error : false);
  };

  const handleDescription = (e) => {
    setDescription(e);
  };



  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const [data, setData] = useState({
    filelength: "",
  });
  const sendData = (data) => {
    setData(data);
  };
  const selectOption = (event) => {
    const getID = [];
    for (let index = 0; index < event.length; index++) {
      getID.push(event[index].id);
    }
    setCategory(getID);
  };


  const selectOption1 = (event) => {
    const getIDs = [];
    for (let index = 0; index < event.length; index++) {
      getIDs.push(event[index].id);
    }
    setChannelId(getIDs);
  };


  const handleSwitchChange = (status) => {
    console.log("status", status)
    if (status === 1) {
      setEnabled(0)
    } else {
      setEnabled(1)
    }
  };

  const handleAllOrg = (e) => {
    setAllSelectOrg(e);
    setChannelId([])
    multiselectRef.current.resetSelectedValues();

  };


  const getTagsList = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extorg/admin/getAllTags`
      );
      setTagsData(response.data.data)
    } catch (error) {
      console.log("error", error)
    }
  }


  const channelList = async () => {
    const response = await axiosInstance.current.get(
      `extorg/admin/getChannelList?ak_id=${akTokenOrg}&keyword=${""}&orgKey=${""}&meta=${2}` //OrgAdd
    );
    setCategoryData(response.data.data)
  }

  useEffect(() => {
    if (profile[0].type === 6) {
      channelList()
    }
  }, [])




  const channelListBySpeficOrg = async () => {
    const response = await axiosInstance.current.get(
      `extorg/admin/getChannelList?ak_id=${akTokenOrg}&keyword=${""}&orgKey=${allSelectOrg}&meta=${2}` //OrgAdd
    );
    setCategoryData(response.data.data)
  }

  useEffect(() => {
    if (allSelectOrg.length > 0) {
      channelListBySpeficOrg()
    } else {
      setCategoryData([])
    }
  }, [allSelectOrg])





  useEffect(() => {
    getTagsList()
  }, [])



  var date = new Date();

  useMemo(() => {
    var components = [
      date.getYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ];
    var id = components.join("");
    setTimes(id);
  }, []);







  useEffect(() => {
    if (thumbnail1) {
      console.log("thumbnail1", thumbnail1)
      const ext = thumbnail1.name.split(".").pop();
      let fileName = `720x405.${ext}`;
      setLargeloader(true);
      // console.log(fileName);
      setLarge(fileName);

      const formData = new FormData();
      formData.append("file", thumbnail1);
      axiosInstanceUpload.current
        .post(
          `uploadthumbnail?video_key=${video_key}&field_name=${fileName}`,
          formData
        )
        .then((response) => {
          setLargeloader(false);
          // console.log(response);
        });
    }
  }, [thumbnail1]);




  useEffect(() => {
    if (thumbnail2) {
      const ext = thumbnail2.name.split(".").pop();
      let fileName = `480x270.${ext}`;
      setSmall(true);
      setMedium(fileName);

      const formData = new FormData();
      formData.append("file", thumbnail1);
      axiosInstanceUpload.current
        .post(
          `uploadthumbnail?video_key=${video_key}&field_name=${fileName}`,
          formData
        )
        .then((response) => {
          setSmall(false);

          // console.log(response);
        });
    }
  }, [thumbnail2]);





  useEffect(() => {
    if (video) {
      const ext = video.name.split(".").pop();
      let fileName = `vid.${ext}`;
      setVideoload(true);
      const formData = new FormData();
      formData.append("file", video);
      axiosInstance.current
        .post(
          `uploadvideo/?video_key=${video_key}&field_name=${fileName}`,
          formData,
          config
        )
        .then((response) => {
          // console.log(response);
          if (response) {
            setVideoload(false);
          }
        });
    }
  }, [video]);





  var config = {
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      // console.log(percentCompleted);
    },
  };


  const handleContinue = async () => {

    if (!videoTitle) {
      videoTitleRef.current.errUpdate()
    }
    if (!description) {
      descriptionRef.current.errUpdate()
    }


    if (
      !videoTitle ||
      !description
    ) {
    } else {
      setDisable(true)
      let json = {
        typeId: type_id,
        title: videoTitle,
        tags: categoryId,
        enabled: enabled,
        large,
        medium,
        created_on: new Date().toISOString(),
        description: description,
        file_size: data.filelength,
        org_id: allSelectOrg || "",
        channels: channeId,
        video_key: video_key,
        link: "",
        sessionId: sessionId,
        created_at: new Date(),
        updated_at: new Date(),
      }

      console.log("jsonnssss", json)
      const response = await axiosInstance.current.post(
        `extorg/admin/uploadVideos?video_key=${video_key}&large=${large}&medium=${medium}&ak_id=${profile[0].ak_id}&org_id=${allSelectOrg || ""}`, json, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      ).then((res) => {
        setMessageType("success");
        setMessage("Video Upload Successfully and Video will be reflect after 15 mins")


        dispatch(noteRefs(!dataRefe))
        
        setTimeout(() => {
          setMessage(false)
          navigate("/post-list")
        }, 3000);
      }).catch((err) => {
        setMessage("Video Not Create")
        setMessageType("error");
      })


    }

  }



  useEffect(() => {
    if (orgchannels) {
      setAllOrgList(orgchannels)
    }
  }, [orgchannels])




  console.log("loadloadloadloadload", load)



  return (
    <>
      <div className="create_video_screen mb_200">
        <div className="row page_header">
          <div className="col-sm-6 col-md-6">
            <h4 className="ph_title">Upload Video</h4>
          </div>
          <div className="col-sm-6 col-md-6 text-end"></div>
        </div>

        <div className="panel">
          {message && <Message message={message} type={messageType} />}
          {/* <form> */}
          <div className="panel-body p_25 pt_50 pb_50">
            <div className="dc_reg_inner">
              <div className="">
                {
                  profile[0].type === 6 ? ("") : (
                    <div className="row">
                      <div className="col-sm-10 col-md-10">
                        <DropdownOrg
                          data={allOrgList}
                          label="Organization"
                          value={allSelectOrg}
                          onChange={handleAllOrg}
                          placeholder="Select"
                          styleClass="mxwp_90_select mb_20 br_10"
                          ref={allOrgRef}
                          validators={[
                            {
                              check: Validators.required,
                              message: "This field is required",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  )
                }

                <div className="row">
                  <div className="col-sm-10 col-md-10 mb_20">
                    <InputField
                      label="Title"
                      value={videoTitle}
                      ref={videoTitleRef}
                      name="videoTitle"
                      type="text"
                      placeholder="Enter video title here"
                      onChange={handleVideoTitle}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                      styleClass="br_10 mxwp_90"
                    />
                  </div>
                  <div className="col-sm-10 col-md-10 mb_20">
                    <InputField
                      label="Description"
                      value={description}
                      type="textarea"
                      placeholder="Enter description here"
                      rows="2"
                      cols="20"
                      onChange={handleDescription}
                      ref={descriptionRef}
                      styleClass="br_10 mxwp_90"
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Thumbnail image (image-size 720X405)</label>
                    <input
                      className="form-control f_14 l_height_38 br_10 mxwp_80"
                      type="file"
                      id="thumbnail1"
                      onChange={(event) =>
                        setThumbnail1(event.target.files[0])
                      }
                      name="thumbnail1"
                      required
                      accept=".jpg,.jpeg,.png"

                    />
                    {largeloader && (
                      <div class="clearfix text-center">
                        <div
                          class="spinner-border"
                          style={{ color: "#3f2783" }}
                          role="status"
                        >
                          <span class="visually-hidden">
                            Loading...
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Thumbnail image (image-size 480X270)</label>
                    <input
                      className="form-control f_14 l_height_38 br_10 mxwp_80"
                      type="file"
                      id="thumbnail2"
                      onChange={(e) => setThumbnail2(e.target.files[0])}
                      name="thumbnail2"
                      required
                      accept=".jpg,.jpeg,.png"
                    />
                    {small && (
                      <div class="clearfix text-center">
                        <div
                          class="spinner-border"
                          role="status"
                          style={{ color: "#3f2783" }}
                        >
                          <span class="visually-hidden">
                            Loading...
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Video</label>
                    <ChunkUpload
                      load={load}
                      video_key={video_key}
                      setVideoload={setVideoload}
                      sendData={sendData}
                      setfileSize={setfileSize}

                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Tags</label>
                    <Multiselect
                      options={tagsData}
                      onSelect={(event) => selectOption(event)}
                      onRemove={(event) => selectOption(event)}
                      displayValue="name"
                      className="multi_select_main"
                    // showCheckbox
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <label>Assign Channels</label>
                    <Multiselect
                      options={categortyData}
                      onSelect={(event) => selectOption1(event)}
                      onRemove={(event) => selectOption1(event)}
                      displayValue="name"
                      className="multi_select_main"
                      ref={multiselectRef}

                    // showCheckbox
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20 d_flex pt_30">
                    <label style={{ width: "25%" }}>Publish</label>
                    <Switch
                      name={`on-off-switch`}
                      tabIndex={1}
                      checked={enabled}
                      onChange={(e) => handleSwitchChange(enabled)}
                    />
                  </div>
                </div>
                {
                  !load ? (
                    <div className="row mb_30 button_box">
                      <div className="col-md-6 col-sm-6"></div>
                      <div className="col-md-6 col-sm-6 text-end">
                        <Button
                          onClick={handleContinue}
                          value="Submit"
                          enable={disble}
                          buttonStyle="btn_fill ms-4 clk_btn_disbled"
                        />
                      </div>
                    </div>
                  ):(
                    <div className="row mb_30 button_box">
                    <div className="col-md-6 col-sm-6"></div>
                    <div className="col-md-6 col-sm-6 text-end">
                      <Button
                        onClick={handleContinue}
                        value="Submit"
                        enable={true}
                        buttonStyle="btn_fill ms-4 clk_btn_disbled"
                      />
                    </div>
                  </div>
                  )
                }

              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
    </>
  );
}

export default CreateVideo;
