import React, { useState } from "react";
import ReactPlayer from "react-player";
const VideoPlayer = ({ url }) => {
  const [state, setPlayed] = useState();
  
  return (
    <div>
      {url !== "" ? (
        <ReactPlayer
          playing={true}
          url={url}
          pip={false}
          muted={false}
          onProgress={(progress) => {
            setPlayed(progress.playedSeconds);
          }}
          controls
          width="100%"
          height="100%"
          style={{ backgroundColor: "black" }}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
          autoPlay
          disablepictureInPicture
          controlslist="nodownload noplaybackrate"
          data-setup="{controllBar: {pictureInPictureTopostle: false}}"
          duration="false"
        />
      ) : (
        "loading..."
      )}
    </div>
  );
};

export default VideoPlayer;
