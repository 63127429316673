import React, { Component, useMemo, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  Navigate,
  useNavigate
} from "react-router-dom";

import LeftSidebar from "./components/header/LeftSidebar";
import TopHeader from "./components/header/TopHeader";

//import MyVisits from "./components/my-visits/MyVisits";
// import Messages from "./components/messages/Messages";
import NotFoundPage from "./components/custom/notfoundpage/NotFoundPage";
// import FormsMap from "./components/scoring-forms/FormsMap";
import { useDispatch } from "react-redux";
import { fetchUserIdSuccess } from "./redux/actions/index";
import "./App.css";
import "./css/style.css";
import useAxios from "./axiosinstance";
import { loginData } from "./redux/actions/index";
import DoctorMap from "./components/doctor-map/DoctorMap";
import { useSelector } from "react-redux";
import Organizations from "./components/organizations/Organizations";
import AdminOrganizations from "./components/admin-organizations/Organizations";
import OrganizationApprovalList from "./components/organizations/org-approval/OrganizationApprovalList";
import EditOrganizationDetails from "./components/organizations/org-edit/EditOrganizationDetails";
import EditAdminOrganizationDetails from "./components/admin-organizations/org-edit/EditOrganizationDetails";
import CreateOrganization from "./components/organizations/create-organization/CreateOrganization";
import Tags from "./components/tags/Tags";
import Users from "./components/my-users/Users";
import EditUserDetails from "./components/my-users/edit-user/EditUserDetails";
import CreateUser from "./components/my-users/create-user/CreateUser";
import Plans from "./components/plans/Plans";
import ResourceType from "./components/resource-types/ResourceType";
import Patients from "./components/patients/Patients";
import EditPatientDetails from "./components/patients/edit-patient/EditPatientDetails";
import CreatePatient from "./components/patients/create-patient/CreatePatient";
import Channels from "./components/channels/Channels";
import Videos from "./components/videos/Videos";
import EditVideoDetails from "./components/videos/EditVideo";
import CreateVideo from "./components/videos/CreateVideo";
import CreateEvent from "./components/videos/CreateEvent";
import Groups from "./components/groups/Groups";
import CreateGroup from "./components/groups/CreateGroup";
import EditGroupDetails from "./components/groups/EditGroupDetails";
import Message from "./components/custom/toster/Message";
import HcpApprovalList from "./components/organizations/hcp-approval/HcpApprovalList";
import Pending from "./components/pendingspecility/Pending";
import Sharings from "./components/sharing/Sharings";
import CreateSharing from "./components/sharing/CreateSharing";
import EditSharingDetails from "./components/sharing/EditSharingDetails";


function AppRouter() {
  let akToken = localStorage.getItem("AKTOKENORG");
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const location = useLocation();
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectToError = () => {
    navigate("/login-error", { replace: true });
    // window.location.reload(false);
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.current.post(
          "extorg/org/signin"
        );
        dispatch(fetchUserIdSuccess(response.data));
      } catch (error) {
        setMessage(error.message);
        setMessageType("error");
        console.log(error.message);
      }
    };
    getData();
  }, []);

  const login = useSelector((state) => state.mainReducer.userDetails.user);
  const loginType = useSelector((state) => state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0])

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await axiosInstance.current.get(
          `extorg/org/userProfile?ak_id=${akToken}`
        );
        if (result.status === 200) {
          if (result.data.status) {
            dispatch(loginData(result.data.data));
          } else {
            redirectToError();
          }
        }
      } catch (err) {
        // setMessage(err.message || "Unexpected Error!");
        console.log(err.message, 'error');
        localStorage.removeItem("AKTOKENORG");
        // setMessageType("error");
      } finally {
        console.log("api response finally");
      }
    };
    getData();

    if (akToken !== null) {
      getData();
    }
  }, []);
  
 
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  // let akToken = sessionStorage.getItem("AKTOKENORG");
  return (
    <>
     {message && <Message message={message} type={messageType} />}
      {login.isEmail === true && akToken !== null && loginType.type === 6 && (
        <div className="" style={{ height: "100%" }}>
          <div className={"App " + (isOpen ? "sidebar-collapsed" : "")}>
            {splitLocation[1] === "shoulder-treatment-form" ? (
              " "
            ) : (
              <>
                <TopHeader />
                <LeftSidebar onClick={() => setIsOpen(!isOpen)} />
              </>
            )}

            <div
              className={
                "content__main " +
                (splitLocation[1] === "shoulder-treatment-form"
                  ? "hide_menus"
                  : "")
              }
            >
              <Routes>
                {/* Org Routes */}
                <Route path="/" element={<Organizations />} />
                <Route path="/organization-list" element={<Organizations />} />
                <Route
                  path="/org-approve-list"
                  element={<OrganizationApprovalList />}
                />
                <Route
                  path="/hcp-approve-list"
                  element={<HcpApprovalList />}
                />
                <Route
                  path="/edit-organization-details/:akou"
                  element={<EditOrganizationDetails />}
                />
                <Route
                  path="/create-organization"
                  element={<CreateOrganization />}
                />
                <Route path="/tags" element={<Tags />} />
                <Route path="/plans" element={<Plans />} />
                <Route path="/pending-speciality" element={<Pending />} />

                <Route path="/resource-types" element={<ResourceType />} />
                {/* User routes */}
                <Route path="/users-list" element={<Users />} />
                <Route path="/edit-user-details/:akou" element={<EditUserDetails />} />
                <Route path="/create-user" element={<CreateUser />} />

                <Route path="/patients-list" element={<Patients />} />
                <Route path="/edit-patient-details" element={<EditPatientDetails />} />
                <Route path="/create-patient" element={<CreatePatient />} />

                <Route path="/channels-list" element={<Channels />} />

                <Route path="/post-list" element={<Videos />} />
                <Route path="/edit-post-details" element={<EditVideoDetails />} />
                <Route path="/create-post" element={<CreateVideo />} />
                <Route path="/create-event/:id" element={<CreateEvent />} />

                <Route path="/groups-list" element={<Groups />} />
                <Route path="/sharing-list" element={<Sharings />} />

                <Route path="/edit-group-details/:groupId" element={<EditGroupDetails />} />
                <Route path="/edit-share-details/:share_id" element={<EditSharingDetails />} />

                <Route path="/create-group" element={<CreateGroup />} />
                <Route path="/create-sharing" element={<CreateSharing />} />

                <Route path="/*" element={<NotFoundPage />} />
              </Routes>
            </div>
          </div>
        </div>
      )}
      {login.isEmail === true && akToken !== null && loginType.type === 2 && (
        <div className="" style={{ height: "100%" }}>
          <div className={"App " + (isOpen ? "sidebar-collapsed" : "")}>
            {splitLocation[1] === "shoulder-treatment-form" ? (
              " "
            ) : (
              <>
                <TopHeader />
                <LeftSidebar onClick={() => setIsOpen(!isOpen)} />
              </>
            )}

            <div
              className={
                "content__main " +
                (splitLocation[1] === "shoulder-treatment-form"
                  ? "hide_menus"
                  : "")
              }
            >
              <Routes>
                {/* User routes */}
                <Route path="/" element={<AdminOrganizations />} />
                <Route path="/organization-list" element={<AdminOrganizations />} />
                <Route
                  path="/edit-organization-details/:akou"
                  element={<EditAdminOrganizationDetails />}
                />

                <Route path="/users-list" element={<Users />} />
                <Route path="/edit-user-details/:akou" element={<EditUserDetails />} />
                <Route path="/create-user" element={<CreateUser />} />
                <Route
                  path="/org-approve-list"
                  element={<OrganizationApprovalList />}
                />
                <Route
                  path="/edit-organization-details/:akou"
                  element={<EditOrganizationDetails />}
                />

                <Route path="/patients-list" element={<Patients />} />
                <Route path="/edit-patient-details" element={<EditPatientDetails />} />
                <Route path="/create-patient" element={<CreatePatient />} />

                <Route path="/channels-list" element={<Channels />} />

                <Route path="/post-list" element={<Videos />} />
                <Route path="/edit-post-details" element={<EditVideoDetails />} />
                <Route path="/create-post" element={<CreateVideo />} />
                <Route path="/create-event/:id" element={<CreateEvent />} />

                <Route path="/groups-list" element={<Groups />} />
                <Route path="/sharing-list" element={<Sharings />} />
                <Route path="/edit-group-details/:groupId" element={<EditGroupDetails />} />
                <Route path="/edit-share-details/:share_id" element={<EditSharingDetails />} />

                <Route path="/create-group" element={<CreateGroup />} />
                <Route path="/create-sharing" element={<CreateSharing />} />

                <Route path="/*" element={<NotFoundPage />} />
              </Routes>
            </div>
          </div>
        </div>
      )}

      {login.isEmail === true && akToken === null && (
        <div className="" style={{ height: "100%" }}>
          <div className=" ">
            <TopHeader chk={true} />
            <div className="">
              <div className="content__main_reg ">
                <Routes>
                  <Route path="/" element={<DoctorMap />} />
                  {/* <Route
                    path="/patient-registration"
                    element={<DoctorRegistration />}
                  /> */}

                  <Route path="/*" element={<NotFoundPage />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      )}

      {login.isEmail === false && akToken === null && (
        <div className="" style={{ height: "100%" }}>
          <div className="App ">
            <TopHeader />
            <div className="tab-content">
              <div className="content__main_reg ">
              <h4>Please login as Super Admin / Org Admin</h4>
                {/* <Routes>
                  <Route
                    path="/patient-registration"
                    element={<DoctorRegistration />}
                  />
                  <Route
                    path="/"
                    element={<Navigate to="/patient-registration" />}
                  />

                  <Route path="/*" element={<NotFoundPage />} />
                </Routes> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AppRouter;
