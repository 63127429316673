import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import useTranslation from "../customHooks/translations";
import { noteRefs } from "../../redux/actions";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";

const PlatformTags = ({ platFormData, editTagsList, org_id, ak_id, doctor_id, email, orgName, platform_id ,resource_id}) => {

  console.log("organization_name4", orgName)
  const AKTOKENORG = localStorage.getItem("AKTOKENORG")

  const [selectedOption, setSelectedOption] = useState([]);
  const [arr2, setArr2] = useState([]);
  const [totalData, setTotalData] = useState([])
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [error, setError] = useState(false)
  const translation = useTranslation();
  const dispatch = useDispatch()
  const axiosInstance = useAxios();

  const [arr, setArr] = useState(
    platFormData.map((option) => ({ ...option, status: 0 }))
  );

  console.log("editTagsList",editTagsList,platFormData)
 

  const handleSelectChange = (selectedValues) => {
    setSelectedOption(selectedValues);
   

    let arr = [];
    selectedValues.map((ele)=>{
      arr.push({
        id:ele.value,
        value:ele.label
      })
    })
    let json = {
      tags:arr
    }
    console.log("selectedValuesselectedValues",arr)
    let apiUrl = `extorg/res_type/updateDocTags?resource_id=${resource_id}&ak_id=${AKTOKENORG}&type=${1}&status=""`
      axiosInstance.current.put(
        apiUrl, json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        setMessage("Tags Updated")
        setMessageType("success");
        dispatch(noteRefs(new Date().getSeconds()))
        setTimeout(() => {
          setMessage(false)
        }, 2000);
      }).catch((err) => {
        dispatch(noteRefs(new Date().getSeconds()))
        setMessage("Tags Not Updated")
        setMessageType("error");
      });
  };



  useEffect(() => {
    setTotalData(platFormData);
  }, [platFormData]);

  useEffect(() => {
    // Set selected options based on the editTagsList array
    const selectedOptions = platFormData.filter((option) =>
      editTagsList.includes(option.value)
    );
    setSelectedOption(selectedOptions);
  }, [platFormData, editTagsList]);




  return (
    <>
      {message && <Message message={message} type={messageType} />}

      <Select
        value={selectedOption}
        isMulti
        onChange={handleSelectChange}
        options={totalData}
        className="multi_select_drp_select"
        placeholder="Select Platforms"
      />
      {
        error ? (
          <span className="text-danger mt-2">
            Please Select at least one platform</span>
        ) : ("")
      }
    </>
  )
}

export default PlatformTags
