//import useState hook to create menu collapse state
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import settingsIconFill from "../../images/Settings.svg";
import Modal from "react-bootstrap/Modal";
import patientsIconFill from "../../images/Patients.svg";
import channelIconFill from "../../images/channels.svg";
import tagIcon from "../../images/Tags.svg";
import subscriptionIcon from "../../images/resource.svg";
import groupIconFill from "../../images/Group.svg";
import PlansIconFill from "../../images/Plans.svg";
import postIconFill from "../../images/Video.svg";
import useAxios from "../../axiosinstance";
import { s3UrlLink } from "../../utilities/S3Url";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import { useSelector } from "react-redux";

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "./LeftSidebar.css";
import arrowCollapse from "../../images/m-arrow-left.svg";
import med from "../../images/organizations.svg";
import useTranslation from "../customHooks/translations";
import Settings from "../settings/Settings";

const LeftSidebar = (props) => {
  const axiosInstance = useAxios();
  const translation = useTranslation();
  const login = useSelector((state) => state.mainReducer.loginData.arr[0]);
  const loginData = useSelector((state) => state.mainReducer.loginData.login);
  const loginType = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const [checkVisit, setCheckVisit] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [limit, setLimit] = useState(10);
  const [role, setRole] = useState("");
  const [akouId, setAkouId] = useState("");
  const [search, setSerach] = useState("");
  const [orgUserHcpList, setOrgUserHcpList] = useState([]);
  
  useEffect(() => {
    if (loginData !== undefined) {
      setCheckVisit(loginData.myVisit);
    }
  }, [loginData]);
  useEffect(() => {
    if (login !== undefined) {
      // setName(login.patient.firstname);
      // setlastName(login.patient.lastname);
      // setEmail(login.patient.email);
    }
  }, [login]);

  const location = useLocation();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/getAllUsers/${login.ak_id}?limit=${limit}&offset=0&role=${3}&email=&username=&sort=&akou_id=`
        );
        setOrgUserHcpList(response.data.data);
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    getData();
  }, []);

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false);

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const [show, setShow] = useState(false);
  let akToken = localStorage.getItem("AKTOKENORG");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const image =  s3UrlLink(loginType.image)

  return (
    <>
      {/* collapsed props to change menu size using menucollapse state */}
      <ProSidebar collapsed={menuCollapse} id="sidebar_main">
        <SidebarHeader>
          <br />
          <div
            className="closemenu"
            onClick={() => {
              menuIconClick();
              props.onClick();
            }}
          >
            {/* changing menu collapse icon on click */}
            {<img src={arrowCollapse} className="left-logo" alt="logo" />}
          </div>
        </SidebarHeader>
        <SidebarContent className="left_sidebar_body">
          <Menu iconShape="square">
            {(loginType.type === 6 || loginType.type === 2) && (
              <MenuItem
                className={
                  splitLocation[1] === "organization-list" ||
                  splitLocation[1] === "create-organization" ||
                  splitLocation[1] === "edit-organization-details" ||
                  splitLocation[1] === ""
                    ? "active"
                    : ""
                }
                icon={<img src={med} alt="menu-icon" className="nav_icon" />}
              >
                {"Organizations"}
                <Link to="/organization-list" />
              </MenuItem>
            )}
            {(loginType.type === 6 || loginType.type === 2) && (
              <MenuItem
                className={
                  splitLocation[1] === "org-approve-list" ? "active" : ""
                }
                icon={
                  <img
                    src={groupIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
              >
                {"Org Joining"}
                <Link to="/org-approve-list" />
              </MenuItem>
            )}
            {loginType.type === 6 && (
              <MenuItem
                className={
                  splitLocation[1] === "hcp-approve-list" ? "active" : ""
                }
                icon={
                  <img
                    src={patientsIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
              >
                {"App Joining"}
                <Link to="/hcp-approve-list" />
              </MenuItem>
            )}
            {loginType.type === 6 && (
              <MenuItem
                className={
                  splitLocation[1] === "resource-types" ? "active" : ""
                }
                icon={
                  <img
                    src={subscriptionIcon}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
              >
                {"Resource Types"}
                <Link to="/resource-types" />
              </MenuItem>
            )}

            {loginType.type === 6 && (
              <MenuItem
                className={
                  splitLocation[1] === "pending-speciality" ? "active" : ""
                }
                icon={
                  <img
                    src={channelIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                    // style={{ width: "26px" }}
                  />
                }
              >
                {"Speciality Approval"}
                <Link to="/pending-speciality" />
              </MenuItem>
            )}

            {(loginType.type === 6 || loginType.type === 2) && (
              <MenuItem
                // className={splitLocation[1] === "users-list" || (loginType.type === 2 && splitLocation[1] === "") ? "active" : ""}
                className={
                  splitLocation[1] === "users-list" ||
                  splitLocation[1] === "create-user" ||
                  splitLocation[1] === "edit-user-details"
                    ? "active"
                    : ""
                }
                icon={
                  <img
                    src={patientsIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
              >
                {"Users"}
                <Link to="/users-list" />
              </MenuItem>
            )}

            {(loginType.type === 6 || (loginType.type === 2 && orgUserHcpList.length !== 0)) && (
              <MenuItem
                className={
                  splitLocation[1] === "groups-list" ||
                  splitLocation[1] === "create-group" ||
                  splitLocation[1] === "edit-group-details"
                    ? "active"
                    : ""
                }
                icon={
                  <img
                    src={groupIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                    // style={{ width: "24px" }}
                  />
                }
              >
                {"Create group"}
                <Link to="/groups-list" />
              </MenuItem>
              
            )}




{(loginType.type === 6 || (loginType.type === 2 && orgUserHcpList.length !== 0)) && (
              <MenuItem
                className={
                  splitLocation[1] === "edit-share-details" ||
                  splitLocation[1] === "sharing-list"
                    ? "active"
                    : ""
                }
                icon={
                  <img
                    src={groupIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                    // style={{ width: "24px" }}
                  />
                }
              >
                {"Sharing"}
                <Link to="sharing-list" />
              </MenuItem>
              
            )}

            {loginType.type === 6 && (
              <MenuItem
                className={splitLocation[1] === "plans" ? "active" : ""}
                icon={
                  <img
                    src={PlansIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
              >
                {"Plans"}
                <Link to="/plans" />
              </MenuItem>
            )}
            {loginType.type === 6 && (
              <MenuItem
                className={splitLocation[1] === "tags" ? "active" : ""}
                icon={
                  <img src={tagIcon} alt="menu-icon" className="nav_icon" />
                }
              >
                {"Tags"}
                <Link to="/tags" />
              </MenuItem>
            )}

            {/* <MenuItem
              className={splitLocation[1] === "patients-list" ? "active" : ""}
              icon={
                <img
                  src={patientsIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
            >
              {"Patients"}
              <Link to="/patients-list" />
            </MenuItem> */}

            {/* {loginType.type === 2 && (
              <MenuItem
                className={
                  splitLocation[1] === "org-approve-list" ? "active" : ""
                }
                icon={
                  <img
                    src={subscriptionIcon}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
              >
                {"Approval List"}
                <Link to="/org-approve-list" />
              </MenuItem>
            )} */}
            {(loginType.type === 6 || loginType.type === 2) && (
              <MenuItem
                className={splitLocation[1] === "channels-list" ? "active" : ""}
                icon={
                  <img
                    src={channelIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                    // style={{ width: "26px" }}
                  />
                }
              >
                {"Med Channels"}
                <Link to="/channels-list" />
              </MenuItem>
            )}
            {(loginType.type === 6 || loginType.type === 2) && (
              <MenuItem
                className={
                  splitLocation[1] === "post-list" ||
                  splitLocation[1] === "create-event" ||
                  splitLocation[1] === "create-post" ||
                  splitLocation[1] === "edit-post-details"
                    ? "active"
                    : ""
                }
                icon={
                  <img
                    src={postIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
              >
                {"Videos / Events"}
                <Link to="/post-list" />
              </MenuItem>
            )}
            {/* <MenuItem
              onClick={handleShow}
              icon={
                <img
                  src={settingsIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
            >
              {translation.settings}
            </MenuItem> */}
          </Menu>
          <SidebarFooter>
          <span className="user_letter">
            {/* {name !== "" && name.slice(0, 1) + lastName.slice(0, 1)} */}

            {loginType.image === "" ? (
                loginType.firstname.slice(0, 1) + login.lastname.slice(0, 1)
              ) : (
                <img src={image} alt={loginType.firstname} />
              )}


          </span>
          <div className="username-info">
            <h5>
              {login.firstname.charAt(0).toUpperCase() +
                login.firstname.slice(1)}{" "}
              {login.lastname.charAt(0).toUpperCase() + login.lastname.slice(1)}
            </h5>
            <p>({akToken})</p>
            <span>{email}</span>
          </div>
          <p className="app_ver_txt_menu_responsive">
            {translation.AppVersion}: {process.env.REACT_APP_VERSION}
          </p>
        </SidebarFooter>
        </SidebarContent>
       
      </ProSidebar>

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        // keyboard={false}
        dialogClassName="modal-lg"
        className="settings_ppup_main"
      >
        <Modal.Header closeButton>
          <Modal.Title>{translation.settings}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="settings_ppup_bdy">
          <Settings />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeftSidebar;
