import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { useSearchParams } from "react-router-dom";
import TagsListTable from "./TagsListTable";
import Message from "../custom/toster/Message";
import { Validators } from "../../utilities/Validator";
import { useSelector } from "react-redux";
import { noteRefs } from "../../redux/actions";
import { useDispatch } from 'react-redux'

const TagsList = () => {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const dispatch = useDispatch()
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(25)
  const [offset, setOffset] = useState(25)
  const [tagsData, setTagsData] = useState([]);
  const [tagName, setTagName] = useState("");
  const [tagOrder, setTagOrder] = useState("");
  const [platform, setPlatform] = useState("");
  const [ShowAddTag, setShowAddTag] = useState(false);
  const [refresh, setRefresh] = useState(false)
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [loader1, setloader1] = useState(false);
  const[disble,setDisable]=useState(false)
  const[totalData,setTotalData]=useState("")

  const [errortitle, setFormerrortitle] = useState({})
  const [errorsubj, setFormerrorsubj] = useState({})

  const AKTOKENORG = localStorage.getItem("AKTOKENORG")
  const handleCloseInvite = () => {

    setTagName("")
    setTagOrder("")
    setShowAddTag(false)
  };

  const tagNameRef = useRef();
  const orderRef = useRef();

  console.log(dataRefe, "dataRefeNikhil")

  const getTagsList = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extorg/admin/listOfTags?platform=${platform}&limit=${limit}&offset=${0}&ak_id=${AKTOKENORG}`
      );
      if (response.status === 200) {
        setloader1(true)
        setTagsData(response.data.data)
        setTotalData(response.data.total_data)
      }

    } catch (error) {
      console.log("error", error)
    }
  }


  const fetchMoreData = () => {

    addMoreData();
    // getTagsList()
  };



  const addMoreData = async () => {
    setOffset(offset + 25);
    const response = await axiosInstance.current.get(
      `extorg/admin/listOfTags?platform=${platform}&limit=${limit}&offset=${offset}&ak_id=${AKTOKENORG}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setTagsData(tagsData.concat(resdata));
    }, 500);
  };



  useEffect(() => {
    getTagsList()
    setOffset(25)
  }, [dataRefe])

  console.log(offset);
  const tagNameChange = (e) => {
    setTagName(e);
    console.log("test", e);
  };
  const platformChange = (e) => {
    setPlatform(e);
  };
  const tagOrderChange = (e) => {
    setTagOrder(e.replace(/[^0-9]/g, ""));
  };
  const handleInviteModal = () => {
    setShowAddTag(true);
  };

  const handleAddTag = async () => {
    try {
      if (!tagName) {
        tagNameRef.current.errUpdate();
      }
      if (!tagOrder) {
        orderRef.current.errUpdate();
      }
      if (
        !tagName ||
        !tagOrder
      ) {
      } else {
        setDisable(true)
        let json = {
          name: tagName,
          order: Number(tagOrder),
          enabled: 1
        }
        const response = await axiosInstance.current.post(
          `extorg/admin/createTags?ak_id=${AKTOKENORG}`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        }
        );
        setMessage(response.data.message)
        setMessageType("success");
        setDisable(false)
        dispatch(noteRefs(!dataRefe))
        setTagName("")
        setTagOrder("")
        setTimeout(() => {
          setMessage(false)
        }, 3000);
        setRefresh(true)
        setTimeout(() => {
          setRefresh(false)
        }, 1500);
        setShowAddTag(false);
      }

    } catch (error) {
      console.log("error", error)
      setMessage(error.response.data.message)
      setMessageType("error");
      setTimeout(() => {
        setMessage(false)
      }, 2000);
    }

  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="tags__list mb_100">
            {message && <Message message={message} type={messageType} />}

            <div className="panel mb_30">
              <div className="panel-heading  pannel_pd">
                <div className="row">
                  <div className="col-sm-4 col-md-6 pt_30">
                    <h4>All Tags</h4>
                    <p className="mb_30">
                        {totalData} {totalData > 1 ? "Results" : "Result"}
                      </p>
                  </div>
                  <div className="col-sm-8 col-md-6 pt_30 text-right d_flex">
                    <div className="d_flex tag_hea_right">
                      <label>Platform: </label>
                      <Dropdown
                        data={[{ value: "1", label: "Med" }]}
                        label=""
                        value={1}
                        onChange={platformChange}
                        placeholder="Select"
                        styleClass="mxw_285 mb_20"
                      />
                    </div>
                    <Button
                      onClick={handleInviteModal}
                      value="+ Add Tag"
                      buttonStyle="btn_fill"
                    />
                  </div>
                </div>
              </div>
              <div className="panel-body p_25">
                {!loader1 ? <div className="no_data">
                  loading...
                </div> : (
                  <>
                    {
                      tagsData.length > 0 ? (
                        <TagsListTable
                          tagsData={tagsData}
                          updatePagination={fetchMoreData}
                          refs={dataRefe}
                          totalData={totalData}
                        />
                      ) : (
                        <div className="no_data">No data found</div>
                      )}

                  </>
                )
                }
                {/* <>
                  {tagsData.length > 0 ? (
                    <TagsListTable
                      tagsData={tagsData}
                      updatePagination={fetchMoreData}
                      refs={dataRefe}
                    />
                  ) : (
                    <div className="no_data">No Data Found</div>
                  )}
                </> */}
              </div>
            </div>
          </div>
          <Modal
            show={ShowAddTag}
            onHide={handleCloseInvite}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-md patient_notes_popup"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center">Add Tag</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <InputField
                label="Tags"
                value={tagName}
                ref={tagNameRef}
                type="text"
                placeholder="Enter tag here"
                styleClass="br_10 mxw_100 mb_20"
                onChange={tagNameChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "* Name is missing",
                  },
                ]}
              />
              <InputField
                label="Order"
                value={tagOrder}
                ref={orderRef}
                type="text"
                placeholder="Enter order here"
                styleClass="br_10 mxw_100 mb_20"
                onChange={tagOrderChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "* Order is missing",
                  },
                ]}
              />

            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "flex-start" }}>
              <Button
                onClick={handleCloseInvite}
                value="Cancel"
                buttonStyle="btn_nill mr_20"
              />
              <Button
                onClick={handleAddTag}
                value="Save"
                width="120px"
                enable={disble}
                buttonStyle="btn_fill ms-4 clk_btn_disbled"
              />
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default TagsList;
