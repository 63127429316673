import React, { useState, useEffect,useRef } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import { Validators } from "../../utilities/Validator";
import { noteRefs } from "../../redux/actions";
import { useDispatch, useSelector } from 'react-redux'

const PlanEdit = ({
  showModal,
  hideModal,
  confirmModal,
  type,
  plan_name,
  hcp,
  patient,
  limit,
  plan_duration,
  id,
  refresh,
  setRefresh,

}) => {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [planName, setPlanName] = useState("");
  const [hcpNumber, setHcpNumber] = useState("");
  const [patientNumber, setPatientNumber] = useState("");
  const [duration, setDuration] = useState("");
  const [dataLimit, setDataLimit] = useState("");
  const [planId, setPlanId] = useState(id)
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [ShowAddPlan, setShowAddPlan] = useState(false);
  const dispatch = useDispatch();
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const[disble,setDisable]=useState(false)

  const planNameRef = useRef();
  const hcpCountRef = useRef();
  const patientCountRefRef = useRef();
  const limitRef = useRef();
  const durationRef = useRef();

  const AKTOKENORG = localStorage.getItem("AKTOKENORG")




  const handleCloseEdit = (e) => {
    hideModal(false);
  };

  const planNameChange = (e) => {
    setPlanName(e);
  };
  const hcpNumberChange = (e) => {
    setHcpNumber(e);
  };
  const patientNumberChange = (e) => {
    setPatientNumber(e);
  };
  const durationChange = (e) => {
    setDuration(e);
  };
  const dataLimitChange = (e) => {
    setDataLimit(e);
  };
  const handleInviteModal = () => {
    setShowAddPlan(true);
  };




  useEffect(() => {
    if(plan_name || hcp || patient || plan_duration || limit){
      setPlanName(plan_name)
      setHcpNumber(hcp)
      setPatientNumber(patient)
      setDuration(plan_duration)
      setDataLimit(limit)
    }
  }, [plan_name,hcp,patient,plan_duration,limit])
  







  const handleUpdatePlan = async () => {
      if (!planName) {
        planNameRef.current.errUpdate();
      }
      if (!duration && Number(duration)<1) {
        durationRef.current.errUpdate();
      }
      if (!hcpNumber) {
        hcpCountRef.current.errUpdate();
      }
      if (!patientNumber) {
        patientCountRefRef.current.errUpdate();
      }
      if (!limit) {
        limitRef.current.errUpdate();
      }
      if (
        !planName ||
        !duration ||
        !hcpNumber ||
        !patientNumber ||
        !limit || !duration || Number(duration) < 1
      ) {

      }
      else{
      setDisable(true)
      let json = {
        name: planName,
        hcp_count: Number(hcpNumber),
        patient_count:Number(patientNumber),
        duration:duration,
        limit:dataLimit
  
      }
      let res = await axiosInstance.current.put(
        `extorg/admin/editPlan?id=${id}&ak_id=${AKTOKENORG}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res)=>{
        setMessage("Plan Updated")
        setMessageType("success");
        setDisable(false)
        dispatch(noteRefs(!dataRefe))
        hideModal(false);
        setTimeout(() => {
          setMessage(false)
        }, 3000);
      }).catch((err)=>{
        console.log("err",err)
        setMessage("Plan Name not be same")
        setMessageType("error");
      })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });

  return (
    <>
      {message && <Message message={message} type={messageType} />}

      <Modal
        show={showModal}
        onHide={hideModal}
        className={``}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-md patient_notes_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Edit Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <InputField
            label="Name"
            value={planName}
            ref={planNameRef}
            type="text"
            placeholder="Enter Plan here"
            styleClass="br_10 mxw_100 mb_20"
            onChange={planNameChange}
            validators={[
              {
                check: Validators.required,
                message: "* Name is missing",
              },
            ]}
          />
          <div className="row">
            <div className="col-md-6">
              <InputField
                label="Maximum Number Of HCP"
                value={hcpNumber}
                ref={hcpCountRef}
                type="text"
                placeholder="Enter Number"
                styleClass="br_10 mxw_100 mb_20"
                onChange={hcpNumberChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "* HCP is missing",
                  },
                ]}
              />
            </div>
            <div className="col-md-6">
              <InputField
                label="Maximum Number Of Patient"
                value={patientNumber}
                ref={patientCountRefRef}
                type="text"
                placeholder="Enter Number"
                styleClass="br_10 mxw_100 mb_20"
                onChange={patientNumberChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "* Patient is missing",
                  },
                ]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <InputField
                label="Duration (in days)"
                value={duration}
                ref={durationRef}
                type="text"
                placeholder="Please add in days"
                styleClass="br_10 mxw_100 mb_20"
                onChange={durationChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "* Duration is missing",
                  },
                  {
                    check: Validators.durationRequired,
                    message: "* Days should be more than 1 ",
                  },
                ]}
                
              />
            </div>
            <div className="col-md-6">
              <InputField
                label="Limit (in GB)"
                value={dataLimit}
                ref={limitRef}
                type="text"
                placeholder="Please add limit in GB"
                styleClass="br_10 mxw_100 mb_20"
                onChange={dataLimitChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "*Limit is missing",
                  },
                  {
                    check: Validators.limitRequired,
                    message: "*Limit should be greater than 1GB",
                  },
                ]}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "flex-start" }}>
          <Button
            onClick={handleCloseEdit}
            value="Cancel"
            buttonStyle="btn_nill mr_20"
          />
          <Button
            onClick={handleUpdatePlan}
            value="Update"
            width="120px"
            enable={disble}
            buttonStyle="btn_fill ms-4 clk_btn_disbled"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PlanEdit;
