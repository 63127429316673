import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom//dropdown/Dropdown";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import { useSearchParams } from "react-router-dom";
import ResourceTypeListTable from "./ResourceTypeListTable";
import { Validators, spacialCharReplace } from "../../utilities/Validator";
import { noteRefs } from "../../redux/actions";

function ResourceTypeList() {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(25);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [loader1, setloader1] = useState(false);
  const[disble,setDisable]=useState(false)
  const[totalData,setTotalData]=useState("")
  const [resourceData, setResourceData] = useState([
    {
      sno: "1",
      title: "Resourcetype1",
    },
    {
      sno: "2",
      title: "Resourcetype2",
    },
    {
      sno: "3",
      title: "Resourcetype3",
    },
  ]);
  const [orgSelect, setOrgSelect] = useState([]);
  const [optionsRequried, setOptionsRequried] = useState([
    { value: 1, label: "option1" },
    { value: 2, label: "option2" },
  ]);
  const [akouId, setAkouId] = useState("");
  const [resourceTypeName, setresourceTypeName] = useState("");
  const [keyName, setKeyName] = useState("");
  const [ShowAddResource, setShowAddResource] = useState(false);
  const [organization, setOrganization] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [allOrgList, setAllOrgList] = useState([]);

  const handleCloseInvite = () =>{ 
    
    setDisable(false)
    setOrganization("")
    setAkouId("")
    setresourceTypeName("")
    setKeyName("")
    setDocumentType("")
    setShowAddResource(false);
  
  
  }
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const apiMsgData = useSelector(state => state.mainReducer.apiMessageReducer.arrMessage);
  const apiMsgTypeData = useSelector(state => state.mainReducer.apiMessageTypeReducer.arrMessageType);

  const orgRef = useRef();
  const orgNameRef = useRef();
  const nameRef = useRef();
  const keyNameRef = useRef();
  const documentTypeRef = useRef();
  const dispatch = useDispatch()

  const resourceTypeNameChange = (e) => {
    setresourceTypeName(e);
  };
  const keyNameChange = (e) => {
    setKeyName(e);
  };
  const handleInviteModal = () => {
    setShowAddResource(true);
  };
  const handleInvRequest = () => {
    handleSave();
    // setShowAddResource(false);
  };
  const handleOrgChange = (e) => {
    setOrganization(e);
  };
  const handleDocumentType = (e) => {
    setDocumentType(e);
  };
  const handleSelectOrgChange = (e) => {
    setOrgSelect(e);
  };
  const handleOrgListData = (e) => {
    setAkouId(e);
  };
  const json = JSON.stringify({
    akou_id: organization === "0" ? "0" : akouId,
    name: resourceTypeName,
    alias: keyName,
    document_type: Number(documentType),
    // order: city,
    // medical_form: stateValue,
  });
  const handleSave = async () => {

    if (!organization) {
      orgRef.current.errUpdate();
    }
    if(organization == "1"){
      if (!akouId) {
        orgNameRef.current.errUpdate();
      }
    }
    if (!resourceTypeName) {
      nameRef.current.errUpdate();
    }
    if (!keyName) {
      keyNameRef.current.errUpdate();
    }
    if (!documentType) {
      documentTypeRef.current.errUpdate();
    }

    if (!organization || (organization === "1" ? !akouId : "") || !resourceTypeName || !keyName || !documentType) {
    } else {
      setDisable(true)
      try {
        const result = await axiosInstance.current.post(
          `extorg/org/create/resource_type?ak_id=${login.ak_id}`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (result.status === 201) {
          setShowAddResource(false)
          setMessageType("success");
          setMessage("Resource type added successfully");
          setDisable(false)
          dispatch(noteRefs(!dataRefe))
          // navigate("/resource-types");
          setTimeout(() => {
            setMessage(false);
          }, 2500);
        }
      } catch (err) {
        setMessage(err.message);
        setDisable(false)
        setMessageType("error");
      } finally {
        console.log("api response finally");
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const getData = async () => {
      const response = await axiosInstance.current.get(
        `extorg/admin/listOfResources?offset=0&limit=${limit}`
      );
      if (response.status === 200) {
        setloader1(true)
        setResourceData(response.data.data);
        setTotalData(response.data.total_data)
      }
      // setTotaldata(response.data.total_data);
    };
    getData();
  },[dataRefe]);

  const addMoreData = async () => {
    setOffset(offset + 25);
    const response = await axiosInstance.current.get(
      `extorg/admin/listOfResources?offset=${offset}&limit=${limit}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setResourceData(resourceData.concat(resdata));
    }, 500);
  };

  const fetchMoreData = () => {
    addMoreData();
  };

  useEffect(() => {
    const getAllOrgList = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/orgs?ak_id=${login.ak_id}`
        );
        
        const newData = response.data.data.map((item) => ({
          value: item.id,
          label: item.o_nm,
          plan_id: item.plan_id
        }));
        setAllOrgList(newData);

      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    getAllOrgList();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
        {apiMsgData && <Message message={apiMsgData} type={apiMsgTypeData} />}
        {message && <Message message={message} type={messageType} />}
          <div className="resource__list mb_100">
            <div className="panel mb_30">
              <div className="panel-heading  pannel_pd">
                <div className="row">
                  <div className="col-sm-6 col-md-6 pt_30">
                    <h4>All Resource Types</h4>
                    <p className="mb_30">
                        {totalData} {totalData > 1 ? "Results" : "Result"}
                      </p>
                  </div>
                  <div className="col-sm-6 col-md-6 pt_30 text-right">
                    <Button
                      onClick={handleInviteModal}
                      value="+ Add Resource Type"
                      buttonStyle="btn_fill w_210"
                    />
                  </div>
                </div>
              </div>
              <div className="panel-body p_25">
               
              {!loader1 ? <div className="no_data">
                        loading...
                      </div> : (
                        <>
                          {
                            resourceData.length > 0 ? (
                              <ResourceTypeListTable
                              resourceData={resourceData}
                              updatePagination={fetchMoreData}
                              totalData={totalData}
                              
                              />
                            ) : (
                              <div className="no_data">No data found</div>
                            )}

                        </>
                      )
                      }
                  {/* {resourceData.length > 0 ? (
                    <ResourceTypeListTable
                      resourceData={resourceData}
                      updatePagination={fetchMoreData}
                    />
                  ) : (
                    <div className="no_data">No Data Found</div>
                  )} */}
                
              </div>
            </div>
          </div>
          <Modal
            show={ShowAddResource}
            onHide={handleCloseInvite}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-md patient_notes_popup resource__type_popup"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center">
                Add ResourceType
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <Dropdown
                data={[
                  { value: "0", label: "For all Organizations" },
                  { value: "1", label: "Selected Organization" },
                ]}
                label="Organization*"
                value={organization}
                onChange={handleOrgChange}
                ref={orgRef}
                placeholder="Select"
                styleClass="mxw_100 br_10 mb_20"
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
               {organization === "1" && (
                <Dropdown
                  data={allOrgList}
                  label="Select Organization*"
                  value={akouId}
                  onChange={handleOrgListData}
                  ref={orgNameRef}
                  placeholder="Select Organization"
                  styleClass="br_10 mxw_100 mb_20"
                  validators={[
                    {
                      check: Validators.required,
                      message: "This field is required",
                    },
                  ]}
                />
                )}
                {/* <>
                  <label>Users</label>
                  <MultiSelect
                    options={optionsRequried}
                    value={orgSelect}
                    //   onSelect={(event) => selectOption(event)}
                    //   onRemove={(event) => selectOption(event)}
                    onChange={handleSelectOrgChange}
                    displayValue="title"
                    className="multi_select_main mb_20"
                    // showCheckbox
                  />
                </> */}
              
              <InputField
                label="Name*"
                value={resourceTypeName}
                type="text"
                ref={nameRef}
                placeholder="Enter name here"
                styleClass="br_10 mxw_100 mb_20"
                onChange={resourceTypeNameChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
              <InputField
                label="Key Name*"
                value={keyName}
                type="text"
                ref={keyNameRef}
                placeholder="Enter key name here"
                styleClass="br_10 mxw_100 mb_20"
                onChange={keyNameChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
              <Dropdown
                data={[
                  { value: "1", label: "Document" },
                  { value: "0", label: "Non Document" },
                ]}
                label="Document Type*"
                value={documentType}
                onChange={handleDocumentType}
                placeholder="Select"
                ref={documentTypeRef}
                styleClass="mxw_100 br_10 mb_20"
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "flex-start" }}>
              <Button
                onClick={handleCloseInvite}
                value="Cancel"
                buttonStyle="btn_nill mr_20"
              />
              <Button
                onClick={handleInvRequest}
                value="Save"
                width="120px"
                enable={disble}
                buttonStyle="btn_fill ms-4 clk_btn_disbled"
              />
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default ResourceTypeList;
