import React,{useState,useEffect} from "react";
import { useTable} from "react-table";
import Switch from "../custom/switch/Switch";
import TableSpinner from "../custom/spinner/TableSpinner";
import TableActionItems from "../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector , useDispatch } from "react-redux";
import { fileSizeBYkb } from "../../utilities/FileSize";
import { documentTime } from "../../utilities/DateFormate";
import PlatformTags from "./PlatformTags";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import { noteRefs } from "../../redux/actions";


function DocumentListTable({ docsdata, updatePagination,total ,specifyTagsData,isConsent }) {
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const AKTOKENORG = localStorage.getItem("AKTOKENORG")
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  // const [doctorID, setDoctorID] = useState(login.ing_ak_id);
  let data = docsdata
  const actionData = {
    view_url: "document-view-details",
   
    delete_url: "",
  };

  const handleCheckboxChange = (row,isConsent) => {
 
    getConsent(row,isConsent)
  };

  const getConsent = async (checkid,consent) => {
    try {
      const response = await axiosInstance.current.get(
        `extorg/org/getDocument?ak_dc=${AKTOKENORG}&res_id=${checkid}&status=${!consent}`
      );
      if(response.status ===200){
        dispatch(noteRefs(new Date().getSeconds()))
        // dispatch(noteRefresh(new Date().getMilliseconds()))
        if(consent===false)
        setMessage("Document is added to consent")
      setMessageType("success");
      
      
      }if(consent===true){
        setMessage("Document is removed from consent")
      setMessageType("success");
   
      }
     setTimeout(()=>{
      setMessage(false)
     },2000)
    } catch {}
  };
  const handleSwitchChange = (status, id, sta) => {
   console.log("status, id",status, id)
    let s;
    if (status === 1) {
      s = 0
    } else {
      s = 1
    }
    axiosInstance.current.put(
      `extorg/res_type/updateDocTags?resource_id=${id}&ak_id=${AKTOKENORG}&type=${2}&status=${Number(s)}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setMessage("Status Updated")
      setMessageType("success");
      dispatch(noteRefs(new Date().getSeconds()))
      setTimeout(() => {
        setMessage(false)
      }, 2000);
    }).catch((err) => {
      dispatch(noteRefs(new Date().getSeconds()))
      setMessage("Status Not Update")
      setMessageType("error");
    });

  };
  useEffect(() => {
    console.log(isConsent, "isConsent"); // Ensure that isConsent is updated when it changes
  }, [isConsent]);
  const isLoadingComplete = true;
  const columns = React.useMemo(
    () => [
      {
        Header: "Document Name",
        accessor: "file_name",
        Cell: (row) => (
          <span>
          <div  style={{paddingRight:"10px"}}>{row.row.original.file_name}</div>  
          </span>
        ),
      },
      // {
      //   Header: "Type of Document",
      //   accessor: "file_type",
      //   Cell: (row) => (
      //     <span className="text-capitalize">
      //       {DocType(row.row.original.file_extension , row.row.original.file_type)}
      //       {/* {row.row.original.file_type.replace("_", " ")} */}
      //     </span>
      //   ),
      // },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: (row) => <span>{row.row.original.created_at} </span>,
      },
      {
        Header: "File Size",
        accessor: "file_size",
        Cell: (row) => <span>{fileSizeBYkb(row.row.original.file_size)}</span>,
      },

      {
        Header: "Consent",
        accessor: "checkbox",
        Cell: (row) => (
          <>
          
            <div
              // className={`form-group custom_check_box`}
             
            >{row.row.original.file_extension === "pdf" && (
              <>
              <input
                className="round-checkbox"
                type="checkbox"
                // checked={row.row.original.isSelected}
                id={row.row.original.id}
                 consent={row.row.original.isConsent}
                // defaultChecked={isConsent}
                onChange={() => handleCheckboxChange(row.row.original.id,row.row.original.isConsent)}
                // onClick={()=>handleCheckboxChange(row.row.original.id,row.row.original.isConsent)}
                isConsent={isConsent}
                checked={row.row.original.isConsent}
                style={{ borderRadius: "50%" }}
              />
              <label className="me-4"></label></>)}
            </div>
          </>
        ),
      },
     
      {
        Header: "Tags",
        accessor: "tags",
        headerClassName: "col-acstatus",
        className: "col-acstatus",
        Cell: (row) => (
          <span>
            <PlatformTags
              platFormData={specifyTagsData && specifyTagsData}
              editTagsList={row.row.original.tags && row.row.original.tags}
              setOptions={[]}
              resource_id={row.row.original.id}

            />

          </span>
        ),
      },
      {
        Header: "Active",
        accessor: "active",
        Cell: (row) => (
          //  console.log("row", row.row.original.sno),
          <span>
            <Switch
              name={`on-off-switch${row.row.original.id}`}
              tabIndex={1}
              checked={row.row.original.active}
              onChange={(e) => handleSwitchChange(row.row.original.active,row.row.original.id)}
            />
          </span>
        ),
      }, 
     

      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          <span className="c_pointer">
            {/* <button onClick={e=> handleEdit(row.row.original)}>Edit</button> */}
            <TableActionItems
              viewAction="false"
              doc_view="true"
              editAction="false"
              feedbackAction="false"
              deleteAction="false"
              arrowAction="false"
              other_link="false"
              show_model="true"
              rowName={row.row.original.id}
              rowId={row.row.original.ea_akpt_id}
              rowData={actionData}
              fileName={row.row.original.file_name}
              link_url={row.row.original.file_path}
              // filelinkUrl={row.row.original.file_url}
              filelinkUrl={row.row.original.file_path}
              filetype={row.row.original.file_extension}
              rowType="document"
            />
          </span>
        ),
      },
    ],
    []
  );
  

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

    
    console.log(isConsent,"isConsent");
  return (
    <>
      {message && <Message message={message} type={messageType} />}

      <div className="tableContainer" id="rpScrollableDivfffff">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDivfffff"
          // loader={<TableSpinner />}
          // loader={ data.length >= 10 && data.length !== data.total  ? <TableSpinner /> : null}
        >
          <table {...getTableProps()} className="custom-tbl  table6">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                        
                      ])}
                      
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default DocumentListTable;
