import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import { Validators } from "../../utilities/Validator";
import { useDispatch, useSelector } from "react-redux";
import { noteRefs } from "../../redux/actions";


const TagEdit = ({ showModal, hideModal, confirmModal, id, type, noteTitle, noteOrder, noteId,setDeleteref }) => {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [tagName, setTagName] = useState("");
  const [tagOrder, setTagOrder] = useState("");
  const [ShowAddTag, setShowAddTag] = useState(false);
  const [updateRTef, setUpdateRef] = useState(false)
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();

  const[disble,setDisable]=useState(false)
  const AKTOKENORG = localStorage.getItem("AKTOKENORG")


  const tagNameRef = useRef();
  const orderRef = useRef();
  const dispatch = useDispatch();
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);

  const handleCloseEdit = (e) => {
    hideModal(false)
  };

  useEffect(() => {
  if(noteTitle || noteOrder){
    setTagName(noteTitle)
    setTagOrder(noteOrder)
  }
  }, [noteTitle,noteOrder])
  


  useEffect(() => {
    setDeleteref(updateRTef)
  }, [updateRTef])


  const tagNameChange = (e) => {
    setTagName(e);
    console.log("test", e);
  };
  const tagOrderChange = (e) => {
    setTagOrder(e);
    console.log("order", e);
  };
  const handleInviteModal = () => {
    setShowAddTag(true);
  };






  const handleEditRequest = async () => {
    if (!tagName) {
      tagNameRef.current.errUpdate();
    }
    if (!tagOrder) {
      orderRef.current.errUpdate();
    }
    if (
      !tagName ||
      !tagOrder
    ) {
    } else {

      setDisable(true)

      let json = {
        name: tagName,
        order: Number(tagOrder)
      }
      let res = await axiosInstance.current.put(
        `extorg/admin/editTags?id=${noteId}&ak_id=${AKTOKENORG}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res)=>{
        setMessage("Tag Updated")
        setMessageType("success");
        setDisable(false)
        dispatch(noteRefs(!dataRefe))
        setUpdateRef(!updateRTef)
        hideModal(false);
        setTimeout(() => {
          setMessage(false)
        }, 3000);
      }).catch((err)=>{
        console.log("err",err)
        setMessage(err.response.data.message)
        setMessageType("error");
      });

  
    }
  }

useEffect(() => {
  setTimeout(() => {
    setIsLoading(false);
  }, 1000);
});

return (
  <>
    {message && <Message message={message} type={messageType} />}


    <Modal
      show={showModal}
      onHide={hideModal}
      className={``}
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-md patient_notes_popup"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center">Edit Tag</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <InputField
          label="Tags"
          value={tagName}
          ref={tagNameRef}
          type="text"
          placeholder="Enter tag here"
          styleClass="br_10 mxw_100 mb_20"
          onChange={tagNameChange}
          validators={[
            {
              check: Validators.required,
              message: "* Name is missing",
            },
          ]}
        />
        <InputField
          label="Order"
          value={tagOrder}
          ref={orderRef}
          type="text"
          placeholder="Enter order here"
          styleClass="br_10 mxw_100 mb_20"
          onChange={tagOrderChange}
          validators={[
            {
              check: Validators.required,
              message: "* Order is missing",
            },
          ]}
        />
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button
          onClick={handleCloseEdit}
          value="Cancel"
          buttonStyle="btn_nill mr_20"
        />
        <Button
          onClick={handleEditRequest}
          value="Update"
          width="120px"
          enable={disble}
          buttonStyle="btn_fill ms-4 clk_btn_disbled"
        />
      </Modal.Footer>
    </Modal>
  </>
);
};

export default TagEdit;
